/* globals Stripe */
'use strict';

const analytics = require('universal-ga'),
    Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    Intent = require('../models/intent'),
    router = require('../utilities/router'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'upgrade col-12 col-md-10 offset-md-1'
    },
    currentPage: 1,
    events: {
        'click button': 'onClickButton'
    },
    pageCount: 3,
    tagName: 'div',
    template: templates.upgrade,
    ui: {
        apply: '[data-action=apply]',
        coupon: '[name=coupon]',
        couponStatus: '[data-name=couponStatus]',
        pay: '[data-name=pay]'
    },
    templateContext: function () {
        const o = {
            subscriptionPrice: user.get('subscriptionPrice'),
        };
        return o;
    },
    getIntent: function () {
        const intent = new Intent(),
            style = { base: { color: '#32325d' } };
        // we assume that coupon codes are always uppercase...
        intent.create({dataSetId: user.get('dataSetId'), jobId: user.get('jobId'), price: user.get('subscriptionCents'), coupon: this.ui.coupon.val().toUpperCase() || ''}, function (error/*, intent*/) {
            if (!error) {
                this.intent = intent;
                this.stripe = Stripe(this.intent.get('publishableKey'));
                this.elements = this.stripe.elements();
                this.card = this.elements.create('card', {style: style});
                this.$el.find('#card-element').empty();
                this.card.mount('#card-element');
                if (!this.form) {
                    this.form = document.getElementById('payment-form');
                    this.form.addEventListener('submit', function listener (event) {
                        event.preventDefault();
                        analytics.event('upgrade', 'initiate');
                        this.stripe.confirmCardPayment(this.intent.get('secretKey'), {
                            payment_method: {
                                card: this.card,
                                billing_details: {
                                    name: (new Date()).toISOString()
                                }
                            }
                        }).then(function (results) {
                            if (results.error) {
                                // Show error to your customer (e.g., insufficient funds)
                                console.log(results.error.message);
                                alert(results.error.message);
                                analytics.event('upgrade', 'error');
                            } else {
                                // the payment has been processed
                                if (results.paymentIntent.status === 'succeeded') {
                                    console.log('payment succeeded');
                                    analytics.event('upgrade', 'success');
                                    user.upgrade();
                                    // window.location = '#compensation';
                                    router.navigate('/concierge/', {trigger: true});
                                }
                            }
                        });
                    }.bind(this));
                }
                this.ui.pay.text('Pay $' + (this.intent.get('price') / 100).toFixed(2));
                if (this.intent.get('coupon')) {
                    this.ui.couponStatus.text('Coupon applied');
                    this.ui.apply.attr('disabled', true);
                    this.ui.coupon.attr('readonly', true);
                }
            } else {
                this.ui.couponStatus.text(error.description);
            }
        }.bind(this));
    },
    onClickButton: function (event) {
        switch (event.currentTarget.dataset.action) {
        case 'apply':
            if (this.ui.coupon.val()) {
                this.getIntent();
            } else {
                this.ui.couponStatus.text('Please enter a coupon code');
            }
            break;
        case 'next':
            this.currentPage++;
            if (this.currentPage > this.pageCount) {
                this.currentPage = 1;
                router.navigate('/concierge/', {trigger: true});
            }
            this.$el.find('[id^="u.p"]').each(function (index, page) {
                $(page).toggleClass('hidden', page.id !== 'u.p' + this.currentPage);
            }.bind(this));
            break;
        default:
            break;
        }
    },
    onRender: function () {
        analytics.pageview('upgrade');
        this.currentPage = 1;
        _.defer(function () {
            this.getIntent();
        }.bind(this));
    }
});
