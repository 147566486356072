'use strict';

const analytics = require('universal-ga'),
    Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'resources col-12'
    },
    ui: {
        section: 'div[data-name=section]'
    },
    tagName: 'div',
    template: templates.resources,
    sections: {
        'complanders-salary-assessments': 'resourcesComplandersSalaryAssessments',
        'your-equity-cheat-sheet': 'resourcesYourEquityCheatSheet',
        'guide-private-company-salaries': 'resourcesGuidePrivateCompanySalaries',
        'guide-invesment-firm-salaries': 'resourcesGuideInvestmentFirmSalaries',
        'salary-increases-private-companies': 'resourcesSalaryIncreasesPrivateCompanies',
        'salary-increases-investment-firms': 'resourcesSalaryIncreasesInvestmentFirms',
        'software-engineers': 'resourcesSoftwareEngineers',
        'data-scientists': 'resourcesDataScientists',
        'associates': 'resourcesAssociates'
    },
    //
    onRender: function () {
        analytics.pageview('resources');
        this.showSection(window.location.pathname.split('/').slice(-2)[0]);
    },
    showSection: function (sectionName) {
        this.ui.section.html(templates[this.sections[sectionName]]());
        // _.each(this.$el.find('div[data-section]'), function (section) {
        //     $(section).toggleClass('d-none', section.dataset.section !== sectionName);
        // });
    }
});
