'use strict';

const Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'all-jobs'
    },
    events: {
        'click span': 'onClickSpan'
    },
    tagName: 'div',
    template: templates.allJobs,
    //
    close: function () {
        this.$el.find('button[data-dismiss=modal]').trigger('click');
    },
    group: function (group) {
        this.templateContext = {modalId: this.modalId, group: group};
        return this;
    },
    initialize: function () {
        this.modalId = this.options.via + 'AllJobs';
        this.templateContext = {modalId: this.modalId, group: this.options.group};
    },
    onClickSpan: function (event) {
        this.trigger('job:selected', event.currentTarget.dataset.id);
        this.close();
    },
    onRender: function () {
        _.defer(function () {
            this.open();
        }.bind(this));
    },
    open: function () {
        $('#' + this.modalId).modal();
    }
});
