'use strict';

const cookies = require('../mothership/utilities/cookies'), // @acavan! TODO: move contents of common back to mothership
    _ = require('lodash'),
    json = require('./configuration.json'),
    //
    configuration = {},
    defaultConfiguration = {
        animation: {
            autoplay: true
        },
        apiUrlBase: 'set via configuration.json',
        apiVersion: 'v1',
        assetsUrlBase: 'set via configuration.json',
        blurString: '$◼︎◼︎◼︎',
        cdnUrlBase: 'set via configuration.json',
        defaultRoute: 'register/',
        // mockdata: {
        //     enumerations: false,
        //     user: false,
        //     reports: false
        // },
        mobileWidth: 767,
        register: {
            singlePage: false
        },
        showTestTips: false,
        analytics: {
            id: 'UA-160478670-1',
            debug: false,
            registerPageNames: [
                'introduction',
                'companyType',
                'clientJobTitleCompanyLocation',
                'clientEarnings',
                'companyInformationClientDemographics',
                'clientEmailPassword',
                'logIn'
            ]
        }
        // testMode: false
    },
    search = window.location.search;
let cookie = cookies.getItem('configuration');

// override default configuration with contents of configuration.json
_.extend(configuration, defaultConfiguration, json);

// override with cookied configuration
if (cookie) {
    console.log('configuration - cookie');
    try {
        cookie = JSON.parse(cookie);
        _.extend(configuration, cookie);
    } catch (error) {
        console.log('configuration - parse failed');
    }
}

// override with configuration items found in the url's search string
// only items that are defined in defaultConfiguration can be set via the search string
if (search) {
    _.each(search.substr(1).split('&'), function (s) {
        const a = s.split('='),
            path = a[0],
            value = a[1];
        let defaultValue;
        if (_.has(configuration, path)) {
            defaultValue = _.get(defaultConfiguration, path);
            switch(typeof defaultValue) {
            case 'boolean':
                _.set(configuration, path, value === undefined ? true : value === 'true'); // eslint-disable-line no-undefined
                break;
            case 'string':
                _.set(configuration, path, value.toString());
                break;
            case 'number':
                _.set(configuration, path, +value);
                break;
            default:
                console.error('configuration.js - unknown type (%s)', path);
                break;
            }
        } else {
            if (path.indexOf('utm_') !== 0) {
                console.error('configuration.js - unknown path (%s)', path);
            }
        }
    });
}
if (configuration.apiVersion) {
    configuration.apiUrlBase += '/' + configuration.apiVersion;
}
// console.log('configuration - %s', JSON.stringify(configuration, null, 4));

module.exports = configuration;
