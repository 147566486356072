'use strict';

const analytics = require('universal-ga'),
    Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    Reports = require('../collections/reports'),
    //
    Report = require('./report'),
    Sidebar = require('./sidebar'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'compensation col-12'
    },
    navigations: [],
    pageCount: 4,
    pageNumber: 1,
    regions: {
        sidebar:           {el: 'div[data-region=sidebar]',           replaceElement: true},
        navigationDesktop: {el: 'div[data-region=navigationDesktop]', replaceElement: true},
        navigationMobile:  {el: 'div[data-region=navigationMobile]',  replaceElement: true}
    },
    tagName: 'div',
    //
    initialize: function () {
        this.template = function () {
            const mode = user.mode(),
                product = user.product(),
                productTips = {
                    private: 'Private',
                    investment: 'Investment',
                    venture: 'Corporate Venture'
                };
            let context = {},
                regions = [],
                productTemplate = 'compensation_empty';
            if (mode === 'dashboard') {
                productTemplate = 'compensation_gratis';
            } else if (product) {
                productTemplate = 'compensation_' + product;
            }
            if (this.reports) {
                regions = this.reports.reportIdsDisplayOrder;
            }
            context = _.extend({
                regions: regions,
                product: product,
                productTemplate: productTemplate,
                productTip: productTips[product],
                subscriptionPrice: user.get('subscriptionPrice') || '_missing_price_'
            }, this.options);
            return templates.compensation(context);
        }.bind(this);
        this.reports = new Reports([], {noInitialFetch: true, via: 'compensation.' + this.cid});
        this.listenTo(this.reports, 'sync', function () {
            // console.log('compensation - reports.sync');
            this.render();
        }.bind(this));
        this.listenTo(user, 'change:mode change:jobId change:postalCode change:industryId change:upgradeInProgress', _.debounce(function () {
            // console.log('compensation - user.change:mode|jobId');
            const reportRegions = this.reports ? this.reports.reportIdsDisplayOrder : [];
            _.each(reportRegions, function (reportRegion) {
                this.getChildView(reportRegion).pending();
            }.bind(this));
            this.reports.refresh();
        }));
    },
    onRender: function () {
        const reportRegions = this.reports ? this.reports.reportIdsDisplayOrder : [];
        if (user.mode()) {
            analytics.pageview('compensation.' + user.mode());
        }
        if (this.reports && this.reports.length) {
            _.each(_.keys(this.regions), function (region) {
                switch (region) {
                case 'sidebar':
                case 'navigationDesktop':
                case 'navigationMobile':
                    // noop
                    break;
                default:
                    this.removeRegion(region);
                    break;
                }
            }.bind(this));
            _.each(this.$el.find('[data-report-region]'), function (region) {
                const reportRegion = region.dataset.reportRegion;
                this.addRegion(reportRegion, '[data-report-region=' + reportRegion + ']');
            }.bind(this));
            _.each(reportRegions, function (reportRegion, index) {
                this.showChildView(reportRegion, new Report({model: this.reports.get(reportRegion), class: 'd-none d-lg-block', mobile: false, upgradable: index > 0 && !user.hasConcierge()}));
                this.showChildView(reportRegion + '_mobile', new Report({model: this.reports.get(reportRegion), class: 'mobile d-lg-none', mobile: true, upgradable: index > 0 && !user.hasConcierge()}));
            }.bind(this));
            this.showChildView('sidebar', new Sidebar());
            this.navigations = [];
            _.each(this.navigations, function (navigation) {
                navigation.updatePageCount(this.pageCount);
                navigation.updatePageNumber(this.pageNumber, true);
            }.bind(this));
        }
    }
});
