'use strict';

const Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'lapsed-subscription'
    },
    events: {
        'click button': 'onClickButton'
    },
    tagName: 'div',
    template: templates.lapsedSubscription,
    //
    close: function () {
        this.$el.find('button[data-dismiss=modal]').trigger('click');
    },
    initialize: function () {
        // this.modalId = this.options.via + 'AllJobs';
        this.templateContext = this.options;
    },
    onClickButton: function (event) {
        switch (event.currentTarget.dataset.action) {
        case 'renew':
            this.close();
            this.trigger('click:renew');
            break;
        default:
            break;
        }
    },
    onRender: function () {
        _.defer(function () {
            this.open();
        }.bind(this));
    },
    open: function () {
        $('#lapsedSubscription').modal();
    }
});
