'use strict';

const handlebars = require('handlebars'),
    //
    // enumerations = require('./enumerations').enumerations,
    templates = require('../../builds/mothershipTemplates'),
    configuration = require('../../common/configuration'),
    enumerations = require('../../common/enumerations'),
    _ = require('lodash'),
    helpers = {};

handlebars.templates = templates;

module.exports = {
    initialize: function (callback) {
        // console.log(handlebars.templates.registerForm);
        this.enumerations = enumerations.enumerations;
        handlebars.registerHelper('allJobs', function (group) {
            // const columnCount = this.enumerations[group + 'DisplayOrder'].length < 150 ? 1 : 3,
            const columnCount = 3,
                jobsPerColumn = this.enumerations[group + 'DisplayOrder'].length / columnCount,
                columns = [],
                makeColumn = function (index) {
                    const a = [];
                    _.each(_.slice(this.enumerations[group + 'DisplayOrder'], index * jobsPerColumn, (index * jobsPerColumn) + jobsPerColumn), function (jobId) {
                        a.push({
                            jobId: jobId,
                            jobName: this.enumerations[group][jobId]
                        });
                    }.bind(this));
                    return a;
                }.bind(this);
            for (let i = 0; i < columnCount; i++) {
                columns.push(makeColumn(i));
            }
            // console.log('%s, %s, %s, (%s/%s)', columns[0].length, columns[1].length, columns[2].length, columns[0].length + columns[1].length + columns[2].length, this.enumerations[group + 'DisplayOrder'].length);
            return templates.allJobsColumnar({colWidth: 12 / columnCount, columns: columns});
        }.bind(this));
        handlebars.registerHelper('blur', function () {
            const value = arguments[0],
                options = arguments[1];
            return value === configuration.blurString ? options.fn(this) : options.inverse(this);
        });
        handlebars.registerHelper('camel', function (s) {
            return _.camelCase(s);
        });
        handlebars.registerHelper('compensation', function (options) {
            return templates[options.productTemplate] ? templates[options.productTemplate](options) : '';
        });
        handlebars.registerHelper('concat', function () {
            return _.slice(arguments, 0, arguments.length - 1).join(' ');
        });
        handlebars.registerHelper('configuration', function (path, options) {
            return _.get(configuration, path) || (options.hash.allowEmpty ? '' : 'configuration - unknown path:' + path);
        });
        handlebars.registerHelper('dateString', function (date) {
            return (new Date(date || new Date())).toISOString().slice(0, 10);  // 2019-02-01
        });
        handlebars.registerHelper('debug', function () {
            if (arguments.length > 1) {
                console.log(_.slice(arguments, 0, arguments.length - 1));
            }
            console.log(this);
        });
        handlebars.registerHelper('enum', function (group, id) {
            // console.log(enumerations);
            if (!this.enumerations[group]) {
                return 'enum - unknown group:' + group;
            }
            if (!this.enumerations[group][id]) {
                return 'enum - unknown id:' + id + ' (' + group + ')';
            }
            const e = this.enumerations[group][id];
            return _.isObject(e) ? e.name : e;
        }.bind(this));
        handlebars.registerHelper('enum_select', function (o) {
            // {{{enum_select titleClass='' label='' group='companyTypeIds' selected=companyTypeId}}}
            // select2 will use the label as the 'placeholder' text
            const hash = o.hash,
                options = [],
                titleClass = hash.titleClass || '', // || 'edit-item-title';
                change = hash.locked ? '<span class="request-edit d-none" data-action="edit">change</span>' : '',
                label = hash.label ? '<label class="' + titleClass + '">' + hash.label + change + '</label>\n' : '',
                optionOverrides = {
                    'Corporate Venture Capital': 'Corporate Venture Capital Firm',
                    'Venture Capital': 'Venture Capital Firm',
                    'Private Equity': 'Private Equity Firm',
                    'Angel': 'Angel Group',
                    'Real Estate': 'Real Estate Firm',
                    'University': 'University Fund'
                };
            // initial option representing a null value. Note that we cannot use these controls to UNSET a set value, just to represent an unset value.
            // if (!hash.selected && !hash.multiple) {
            if (!hash.multiple) {
                hash.noChoice = hash.noChoice || 'noChoiceValue';
                options.push({
                    disabled: true,
                    option: hash.placeholder || '',
                    order: -1,
                    selected: true,
                    value: hash.noChoice
                });
            }
            if (hash.selected === -1) {
                // -1 indicates that the first options should be selected...
                hash.selected = _.keys(this.enumerations[hash.group])[0];
            }
            if (this.enumerations[hash.group]) {
                if (this.enumerations[hash.group + 'DisplayOrder']) {
                    _.each(this.enumerations[hash.group + 'DisplayOrder'], function (id) {
                        id = +id;
                        if (this.enumerations[hash.group][id]) { // display order can be a superset of the ids in use (i'm looking at you product specific job lists...)
                            options.push({
                                option: optionOverrides[this.enumerations[hash.group][id]] || this.enumerations[hash.group][id],
                                // order: hash.order || 1,
                                selected: hash.multiple ? _.includes(hash.selected, id) : id === hash.selected,
                                value: id
                            });
                        }
                    }.bind(this));
                } else {
                    _.each(this.enumerations[hash.group], function (text, id) {
                        id = +id;
                        options.push({
                            option: optionOverrides[text] || text,
                            // order: hash.order || 1,
                            selected: hash.multiple ? _.includes(hash.selected, id) : id === hash.selected,
                            value: id
                        });
                    });
                    options.sort(function (a, b) {
                        if (a.value === hash.noChoice || b.value === hash.noChoice) {
                            return a.value === hash.noChoice ? -1 : 1;
                        }
                        return a.option.localeCompare(b.option);
                    });
                }
            } else {
                console.log('handlebars.enum_select - unknown group:%s', hash.group);
            }
            if (hash.doNotKnow) {
                options.push({
                    option: 'I don\'t know',
                    value: 'doNotKnowValue'
                });
            }
            return label + templates.select({
                disabled: hash.disabled,
                invalidFeedback: hash.required ? (hash.invalidFeedback || 'This field is required') : '',
                placeholder: hash.placeholder,
                multiple: hash.multiple,
                name: hash.name || (hash.group.substr(-3) === 'Ids' ? hash.group.substr(0, hash.group.length - 1) : hash.group),
                options: options,
                other: hash.other ? true : false,
                required: hash.required,
                selectedValue: hash.selected
            });
        }.bind(this));
        handlebars.registerHelper('kebab', function (s) {
            return _.kebabCase(s || '');
        });
        handlebars.registerHelper('ifConfiguration', function () {
            const keyValue = arguments[arguments.length - 2],
                options = arguments[arguments.length - 1],
                a = keyValue.split('=');
            let isTrue = true;
            if (a.length === 1) {
                isTrue = configuration[a[0]] ? true : false;
            } else {
                isTrue = configuration[a[0]] == a[1]; // allow casting
            }
            return isTrue ? options.fn(this) : options.inverse(this);
        });
        handlebars.registerHelper('ifArrayIncludes', function () {
            const values = _.slice(arguments, 0, arguments.length - 2),
                array = arguments[arguments.length - 2],
                options = arguments[arguments.length - 1];
            return _.intersection(array, values).length ? options.fn(this) : options.inverse(this);
        });
        handlebars.registerHelper('ifListIncludes', function () {
            const a = _.values(arguments),
                value = a[0] || '',
                list = a.slice(1, a.length - 1),
                options = a.slice(a.length - 1)[0];
            return list.includes(value.split('/')[0]) ? options.fn(this) : options.inverse(this); // re: .split('/')[0] - e.g. 'assessments/first' -> 'assessments'
        });
        handlebars.registerHelper('img', function (options) {
            // <img src="{{configuration 'assetsUrlBase'}}/grafix/stocks.svg" alt="stocks">
            const hash = options.hash;
            let a = ['<img ', 'src="', configuration.assetsUrlBase, '/grafix/', hash.src, '" '];
            if (hash.class) {
                a = a.concat(['class="', hash.class + '" ']);
            }
            if (hash.alt) {
                a = a.concat(['alt="', hash.alt, '" ']);
            }
            a.push('>');
            return a.join('');
        });
        handlebars.registerHelper('mobileRow', function (o) {
            const a = [],
                columnHeaders = o.hash.columnHeaders,
                columnsPerRow = o.hash.columnsPerRow,
                row = o.hash.row,
                values = o.hash.values;
            for (let i = (row * columnsPerRow); i < (row * columnsPerRow) + columnsPerRow; i++) {
                a.push(templates.tableMobileRow({header: columnHeaders[i + 1], value: values[i], blur: values[i] === configuration.blurString}));
            }
            return a.join('\n');
        });
        handlebars.registerHelper('pageSelectors', function (pageCount) {
            const a = ['<ul class="page-selectors">'];
            for (let i = 0; i < pageCount; i++) {
                a.push(templates.pageSelector({page: i + 1, selected: i === 0}));
            }
            a.push('</ul>');
            return a.join('\n');
        });
        handlebars.registerHelper('select_yesNo', function (o) {
            const hash = o.hash;
            hash.yesNo = true;
            if (hash.value) {
                hash.yes = true;
            } else {
                if (!_.isUndefined(hash.selected)) {
                    hash[+hash.selected === 1 ? 'yes' : 'no'] = true;
                } else {
                    // hash.yes = true;
                    hash.noSelection = true;
                }
            }
            return templates.yesNo_noYes(hash);
        });
        handlebars.registerHelper('select_currentJobOrJobOffer', function (o) {
            const hash = o.hash;
            if (hash.value) {
                hash.jobOffer = true;
            } else {
                if (!_.isUndefined(hash.selected)) {
                    hash[+hash.selected === 1 ? 'jobOffer' : 'currentJob'] = true;
                } else {
                    hash.noSelection = true;
                }
            }
            return templates.offerOptions(hash);
        });
        handlebars.registerHelper('select_noYes', function (o) {
            const hash = o.hash;
            hash.noYes = true;
            if (hash.value) {
                hash.yes = true;
            } else {
                if (!_.isUndefined(hash.selected)) {
                    hash[+hash.selected === 1 ? 'yes' : 'no'] = true;
                } else {
                    // hash.no = true;
                    hash.noSelection = true;
                }
            }
            return templates.yesNo_noYes(hash);
        });
        handlebars.registerHelper('toLowerCase', function (s) {
            return s.toLowerCase();
        });
        handlebars.registerHelper('toSentenceCase', function (s) {
            const a = s.split(' ');
            a[0] = _.startCase(a[0]);
            return a.join(' ');
        });
        handlebars.registerHelper('toTitleCase', function (s) {
            return _.startCase(s);
        });
        handlebars.registerHelper('toUpperCase', function (s) {
            return s.toUpperCase();
        });
        handlebars.registerHelper('truncate', function (n) {
            return Math.floor(n);
        });
        handlebars.registerHelper('urlEncode', function (s) {
            return encodeURIComponent(s);
        });
        handlebars.registerHelper('year', function () {
            return (new Date()).getFullYear();
        });

        this.registerTemplatePartials();

        if (callback) {
            callback(null);
        }
    },
    registerHelper: function (name, helper) {
        handlebars.registerHelper(name, helper);
    },
    registerTemplatePartials: function () {
        _.each([
            'doNotKnow',
            'invalidFeedback',
            'leverBaseSalary',
            'leverCallout',
            'other',
            'registerNext',
            'resourcesAssociates',
            'resourcesComplandersSalaryAssessments',
            'resourcesDataScientists',
            'resourcesGuideInvestmentFirmSalaries',
            'resourcesGuidePrivateCompanySalaries',
            'resourcesSalaryIncreasesInvestmentFirms',
            'resourcesSalaryIncreasesPrivateCompanies',
            'resourcesSoftwareEngineers',
            'resourcesYourEquityCheatSheet',
            'salaryIncreaseFactors'
        ], function (name) {
            handlebars.registerPartial(name, templates[name]);
        });
    },
    helpers: helpers,
    // templates: templates
    templates: handlebars.templates
};
