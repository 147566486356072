'use strict';

const analytics = require('universal-ga'),
    Marionette = require('backbone.marionette'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'privacy col-12'
    },
    tagName: 'div',
    template: templates.privacy,
    //
    onRender: function () {
        analytics.pageview('privacy');
    }
});

