'use strict';
// console.time('start');
const analytics = require('universal-ga'),
    async = require('async'),
    Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    select2 = require('select2'),
    $ = require('jquery'),
    bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min.js'), // eslint-disable-line no-unused-vars
    // _ = require('lodash'),
    //
    user = require('./models/user'),
    configuration = require('../common/configuration'),
    enumerations = require('../common/enumerations'),
    // templates needs to be loaded before handlebars
    // and templates and handlebars have to go before any views that use the templates
    // (albeit just the one time here...)
    // templates = require('../builds/mothershipTemplates'),
    handlebars = require('./utilities/handlebars'),
    //
    Container = require('./views/container'),
    // Main = require('./views/main'),
    Application = Marionette.Application.extend({
        region: 'main',
        onStart: function () {
            this.showView(new Container());
        }
    }),
    application = new Application();

select2(true, $);

$(function () {
    async.series([
        function (callback) {
            user.ready(callback);
        },
        function (callback) {
            enumerations.initialize(callback);
        },
        function (callback) {
            handlebars.initialize(callback);
        }
    ], function () {
        analytics.initialize(configuration.analytics.id, {debug: configuration.analytics.debug});
        $.getScript('https://js.stripe.com/v3/', function () {
            console.log('stripe loaded');
        });
        Backbone.history.start({hashChange: false, pushState: true});
        application.start();
    });
});

