'use strict';

const Marionette = require('backbone.marionette'),
    // select2 = require('select2'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    router = require('../utilities/router'),
    //
    AccountForm = require('./accountForm'),
    AllJobs = require('./allJobs'),
    //
    enumerations = require('../../common/enumerations').enumerations,
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'account col-12'
    },
    childViewEvents: {
        'job:selected': 'onJobSelected'
    },
    events: {
        'change input':  'statify',
        'change select': 'statify',
        'click button':  'onClickButton'
    },
    regions: {
        allJobs:     {el: 'div[data-region=allJobs]',     replaceElement: true},
        sidebar:     {el: 'div[data-region=sidebar]',     replaceElement: true},
        accountForm: {el: 'div[data-region=accountForm]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.account,
    templateContext: function () {
        const combination = enumerations.productCombinations[user.get('productCombinationId')],
            product = enumerations.products[combination.productId],
            productName = product.product, // meh...
            o = {
                mode: 'account',
                account: true,
                group: productName + 'JobIds',
                password: user.password(),
                product: productName,
                productId: product.id,
                yearId: product.yearId,
                title: 'Account',
                concierge: user.hasConcierge()
            };
        o[user.get('product')] = true;
        return _.extend(o, user.toJSON());
    },
    ui: {
        'form': 'form'
    },
    initialize: function () {
        this.listenTo(user, 'change:mode', _.debounce(function () {
            console.log('account - user.change:mode');
            this.render();
        }));
    },
    onClickButton: function (event) {
        let serializedArray, productName;
        switch (event.currentTarget.dataset.action) {
        case 'allJobs':
            if (!this.getChildView('allJobs')) {
                productName = enumerations.products[user.get('productId')].product;
                this.showChildView('allJobs', new AllJobs({via: 'accounnt', group: productName + 'JobIds'}));
            } else {
                this.getChildView('allJobs').open();
            }
            break;
        case 'submit':
            if (this.validateSelects() && this.ui.form[0].checkValidity()) {
                serializedArray = this.ui.form.serializeArray();
                // console.log(JSON.stringify(serializedArray) === JSON.stringify(this.priorSerializedArray) ? 'same, same' : '!same');
                user.update(serializedArray);
                router.navigate(user.mode() === 'concierge' ? '/concierge/' : '/dashboard/', {trigger: true});
                // window.location = '#compensation';
                window.scrollTo(0, 0);
            } else {
                this.ui.form.toggleClass('was-validated', true);
            }
            break;
        default:
            break;
        }
    },
    onJobSelected: function (jobId) {
        this.$el.find('select[name=jobId]').val(jobId).trigger('change.select2');
    },
    onRender: function () {
        this.$el.find('select').each(function (index, select) {
            $(select).select2({placeholder: select.dataset.label, width: '100%'});
        });
        this.showChildView('accountForm', new AccountForm(this.templateContext()));
        this.priorSerializedArray = this.ui.form.serializeArray();
        this.$el.find('select[name=regionId]').trigger('change'); // get the zip code bits configured...
        this.$el.find('select[name=studentLoanId]').trigger('change'); // get the zip code bits configured...
    },
    statify: function (event) {
        const target =  event.currentTarget,
            name = target.name,
            type = target.type;
        let compensationType;
        switch (name) {
        case 'compensationTypeId':
            compensationType = enumerations.compensationTypeIds[event.currentTarget.value].toLowerCase();
            this.$el.find('div[data-group=salary]').each(function (index, group) {
                const el = $(group);
                el.find('input').attr('disabled', compensationType !== 'salary');
                el.toggleClass('hidden', compensationType !== 'salary');
            });
            this.$el.find('div[data-group=hourly]').each(function (index, group) {
                const el = $(group);
                el.find('input').attr('disabled', compensationType !== 'hourly');
                el.toggleClass('hidden', compensationType !== 'hourly');
            });
            break;
        case 'regionId':
            this.$el.find('input[name=postalCode]').attr('disabled', +event.currentTarget.value !== enumerations.regionNames['United States']);
            break;
        case 'studentLoanId':
            this.$el.find('input[name=studentDebtAmount], input[name=studentLoanMonthlyPayment], select[name=studentLoanPaymentDurationId]').attr('disabled', +event.currentTarget.value === 1);
            this.$el.find('input[name=studentLoanPaymentDurationId]').attr('disabled', +event.currentTarget.value !== 1);
            break;
        default:
            // console.log('register.statify - name:%s', name);
            break;
        }
        switch (type) {
        case 'checkbox':
            if (target.dataset.toggle === 'disable') {
                this.$el.find(target.dataset.enable).attr('disabled', !target.checked);
                this.$el.find(target.dataset.disable).attr('disabled', target.checked).val('noChoiceValue');
                $(target.dataset.disable).trigger('change');
            }
            break;
        case 'select-one':
            if (target.dataset.toggle === 'disable') {
                this.$el.find(target.dataset.enable).attr('disabled', +target.value === 0).val('');
                this.$el.find(target.dataset.disable).attr('disabled', +target.value === 1).val('');
            }
            break;
        default:
            break;
        }
    },
    validateSelects: function () {
        const selects = this.$el.find('form select:not(:disabled)');
        let valid = true;
        selects.each(function (index, select) {
            let v;
            if (select.required) {
                v = select.value !== 'noChoiceValue';
                select.setCustomValidity(v ? '' : 'invalid');
                if (!v) {
                    valid = false;
                }
            }
        });
        return valid;
    }
});
