/* globals qp */
'use strict';

const analytics = require('universal-ga'),
    Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    AllJobs = require('./allJobs'),
    Navigation = require('./navigation'),
    RegisterForm = require('./registerForm'),
    user = require('../models/user'),
    configuration = require('../../common/configuration'),
    enumerations = require('../../common/enumerations').enumerations,
    router = require('../utilities/router'),
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'register col-12'
    },
    childViewEvents: {
        'change:pageNumber': 'onChangePageNumber',
        'job:selected':      'onJobSelected'
    },
    events: {
        'change input':          'statify',
        'change select':         'statify',
        'click button':          'onClickButton',
        'click small':           'onClickButton',
        'dblclick @ui.secretH1': 'mockify'
    },
    lastPageNumber: -1,
    navigations: [],
    pageCount: 5,
    pageNumber: 0,
    regions: {
        allJobs:           {el: 'div[data-region=allJobs]',           replaceElement: true},
        navigationDesktop: {el: 'div[data-region=navigationDesktop]', replaceElement: true},
        navigationMobile:  {el: 'div[data-region=navigationMobile]',  replaceElement: true},
        registerForm:      {el: 'div[data-region=registerForm]',      replaceElement: true}
    },
    tagName: 'div',
    template: templates.register,
    templateContext: function () {
        const o = {
            mode: 'register',
            register: true
        };
        return _.extend(o, {singlePage: configuration.register.singlePage === true ? true : false}, user.toJSON());
    },
    ui: {
        form: 'form',
        pages: 'div[data-page]',
        industryTip: 'span[data-name=industryTip]',
        secretH1: 'h1[data-name=secretH1]'
    },
    //
    mockify: function () {
        const timestamp = (new Date()).toISOString().replace(/-|:/g, '').replace('T', '').substr(0,12),
            random = function (min, max) { // min and max included
                return Math.floor(Math.random() * (max - min + 1) + min);
            },
            values = [];
        let keys,
            name;
        this.$el.find('input, select').each(function (index, element) {
            switch (element.type) {
            case 'select-multiple':
                name = element.name;
                keys = enumerations[name + 's'] ? _.keys(enumerations[name + 's']) : [];
                if (keys.length) {
                    for (let i = 0; i < 3; i++) {
                        values.push(keys[random(0, keys.length - 1)]);
                    }
                    $(element).val(values).trigger('change.select2');
                }
                break;
            case 'select-one':
                name = element.name;
                switch (name) {
                case 'jobId':
                    keys = _.values(enumerations[this.$el.find('input[name=product]').val() + 'JobIdsDisplayOrder']);
                    $(element).val(keys[random(0, keys.length - 1)]).trigger('change.select2');
                    break;
                case 'productCombinationId':
                    // noop
                    break;
                default:
                    keys = enumerations[name + 's'] ? _.keys(enumerations[name + 's']) : [];
                    $(element).val(keys[random(0, keys.length - 1)]).trigger('change.select2');
                    break;
                }
                break;
            case 'checkbox':
                $(element).prop('checked', _.random(0, 1));
                break;
            case 'email':
                element.value = 'cms_' + timestamp;
                break;
            case 'password':
                element.value = 'cms_' + timestamp;
                break;
            case 'text':
                switch (element.name) {
                // these should already be set or not set at all
                case 'dataSetId':
                case 'product':
                case 'productCombinationId':
                case 'productId':
                case 'yearId':
                    break;
                case 'postalCode':
                    element.value = '12345';
                    break;
                default:
                    element.value = timestamp;
                    break;
                }
                break;
            case 'search':
                break;
            default:
                console.log('register.mockify - unknown type (%s)', element.type);
                break;
            }
        }.bind(this));
    },
    onChangePageNumber: function (pageNumber) {
        $('body').toggleClass('form-big', pageNumber < 3);
        this.pageNumber = pageNumber;
        this.updatePage();
    },
    onClickButton: function (event) {
        let el;
        switch (event.currentTarget.dataset.action) {
        case 'allJobs':
            if (!this.getChildView('allJobs')) {
                this.showChildView('allJobs', new AllJobs({via: 'register', group: this.productName + 'JobIds'}));
            } else {
                this.getChildView('allJobs').group(this.productName + 'JobIds').render();
            }
            break;
        case 'login':
            router.navigate('/login/', {trigger: true});
            break;
        case 'next':
            if (this.validateSelects() && this.ui.form[0].checkValidity()) {
                this.pageNumber++;
                _.each(this.navigations, function (navigation) {
                    navigation.updatePageNumber(this.pageNumber, true);
                }.bind(this));
            } else {
                this.ui.form.toggleClass('was-validated', true);
            }
            break;
        case 'register':
            if (this.validateSelects() && this.ui.form[0].checkValidity()) {
                user.create(this.$el.find('form').serializeArray());
                this.pageNumber++;
                _.each(this.navigations, function (navigation) {
                    navigation.$el.hide();
                }.bind(this));
                this.updatePage();
            } else {
                this.ui.form.toggleClass('was-validated', true);
            }
            break;
        case 'rewind': // @acavan! - DEPRECATED...
            this.pageNumber = 0;
            this.render();
            break;
        case 'showPassword':
            el = this.$el.find('input[name=password]')[0];
            el.type = el.type === 'password' ? 'text' : 'password';
            break;
        default:
            break;
        }
        return false;
    },
    onJobSelected: function (jobId) {
        this.$el.find('select[name=jobId]').val(jobId).trigger('change.select2');
    },
    onRender: function () {
        this.navigations = [];
        this.navigations.push(this.showChildView('navigationDesktop', new Navigation({mode: 'desktop', via: 'register'})));
        this.navigations.push(this.showChildView('navigationMobile', new Navigation({mode: 'mobile', via: 'register'})));
        _.each(this.navigations, function (navigation) {
            navigation.updatePageCount(this.pageCount);
            navigation.updatePageNumber(this.pageNumber, true);
        }.bind(this));
        this.$el.find('select').each(function (index, select) {
            $(select).select2({placeholder: select.dataset.label, width: '100%'});
        });
        this.updatePage();
    },
    statify: function (event) {
        const target =  event.currentTarget,
            name = target.name,
            type = target.type,
            value = target.value,
            productTips = {
                private: 'Private',
                investment: 'Investment',
                venture: 'Corporate Venture'
            };
        let combination,
            product,
            productName,
            compensationType;
        switch (name) {
        case 'compensationTypeId':
            compensationType = enumerations.compensationTypeIds[event.currentTarget.value].toLowerCase();
            this.$el.find('div[data-group=salary]').each(function (index, group) {
                const el = $(group);
                el.find('input').attr('disabled', compensationType !== 'salary');
                el.toggleClass('hidden', compensationType !== 'salary');
            });
            this.$el.find('div[data-group=hourly]').each(function (index, group) {
                const el = $(group);
                el.find('input').attr('disabled', compensationType !== 'hourly');
                el.toggleClass('hidden', compensationType !== 'hourly');
            });
            break;
        case 'productCombinationId':
            combination = enumerations.productCombinations[event.currentTarget.value];
            product = enumerations.products[combination.productId];
            productName = product.product; // meh...
            this.ui.form.removeClass(); // removes everything...
            this.ui.industryTip.text(productTips[productName] || '...');
            if (value !== '-1') {
                this.ui.form.addClass(product.product);
                this.showChildView('registerForm', new RegisterForm({
                    dataSetId: product.dataSetId,
                    group: productName + 'JobIds',
                    product: productName,
                    productId: product.id,
                    yearId: product.yearId
                }));
                this.delegateEvents();
                this.bindUIElements();
                this.updatePage();
                this.productName = productName;
                this.ui.form.find('input, select').on('invalid', function (event) { // eslint-disable-line no-shadow
                    console.log('register.statify - validation failed (%s)', event.currentTarget.name);
                });
            } else {
                this.productName = '';
            }
            break;
        case 'regionId':
            this.$el.find('input[name=postalCode]').attr('disabled', +event.currentTarget.value !== enumerations.regionNames['United States']);
            break;
        case 'studentLoanId':
            this.$el.find('input[name=studentDebtAmount], input[name=studentLoanMonthlyPayment], select[name=studentLoanPaymentDurationId]').attr('disabled', +event.currentTarget.value === 1);
            this.$el.find('input[name=studentLoanPaymentDurationId]').attr('disabled', +event.currentTarget.value !== 1);
            break;
        default:
            // console.log('register.statify - name:%s', name);
            break;
        }
        switch (type) {
        case 'checkbox':
            if (target.dataset.toggle === 'disable') {
                this.$el.find(target.dataset.enable).attr('disabled', !target.checked);
                this.$el.find(target.dataset.disable).attr('disabled', target.checked).val('noChoiceValue');
                $(target.dataset.disable).trigger('change');
            }
            break;
        case 'select-one':
            if (target.dataset.toggle === 'disable') {
                this.$el.find(target.dataset.enable).attr('disabled', (target.value === 'noChoiceValue' || +target.value === 0));
                if (!this.updatePageInProgress) {
                    this.$el.find(target.dataset.enable).val('');
                }
                this.$el.find(target.dataset.disable).attr('disabled', +target.value === 1).val('');
            }
            this.validateSelects();
            break;
        default:
            break;
        }
    },
    updatePage: function () {
        this.updatePageInProgress = true;
        if (this.pageNumber === 9) {
            // 'thank you for registering...'
            this.$el.prepend('<img src="https://q.quora.com/_/ad/34bef88e638845e0abed2dfb2f73099b/pixel?tag=ViewContent&noscript=1"/>');
            qp('track', 'CompleteRegistration');
        }
        if (this.lastPageNumber !== this.pageNumber) {
            // console.log('register.updatePage (%s)', this.pageNumber);
            analytics.pageview('register.' + configuration.analytics.registerPageNames[this.pageNumber]);
            this.lastPageNumber = this.pageNumber;
        }
        this.ui.form.toggleClass('was-validated', false);
        // enbable inputs and selects on the current page
        // and disable on pages which have yet to be visited
        // (and don't mess with pages that have been visited)
        _.each(this.ui.pages, function (page, pageIndex) {
            if (pageIndex >= this.pageNumber) {
                $('input, select', page).each(function (index, element) {
                    const disable = pageIndex - 1 >= this.pageNumber;
                    if (!element.dataset.defaultDisabled) {
                        $(element).attr('disabled', disable);
                    }
                }.bind(this));
            }
            if (pageIndex === this.pageNumber) {
                $(page).find('select[data-toggle=disable]').each(function (index, element) {
                    $(element).trigger('change');
                });
            }
        }.bind(this));
        if (!configuration.register.singlePage) {
            this.ui.pages.each(function (index, page) {
                $(page).toggleClass('hidden', +page.dataset.page !== this.pageNumber);
            }.bind(this));
        }
        this.updatePageInProgress = false;
    },
    validateSelects: function () {
        const selects = this.$el.find('form select:not(:disabled)');
        let valid = true;
        selects.each(function (index, select) {
            let v;
            if (select.required) {
                v = select.value !== 'noChoiceValue';
                select.setCustomValidity(v ? '' : 'invalid');
                if (!v) {
                    valid = false;
                }
            }
        });
        return valid;
    }
});
