'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    // _ = require('lodash'),
    //
    user = require('../models/user'),
    //
    configuration = require('../../common/configuration'),
    router = require('../utilities/router'),
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'header'
    },
    events: {
        // 'click a': 'onClickButton',
        'click span': 'onClickButton'
    },
    template: templates.header,
    templateContext: function () {
        return {
            authenticated: user.authenticated(),
            concierge: user.hasConcierge(),
            homeLink: !user.authenticated() ? '/register/' : '/concierge/',
            version: configuration.cdnUrlBase ? configuration.cdnUrlBase.substr(-40, 7) : '...'
        };
    },
    tagName: 'div',
    ui: {
        accountDropdownToggle: '[data-account-dropdown-toggle=true]',
        accountDropdown:       '[data-account-dropdown=true]',
        mobileMenuContents:    '[data-name=mobileMenuContents]',
    },
    initialize: function () {
        this.listenTo(user, 'change:id', function () {
            // console.log('header - user.change:id');
            this.render();
        });
    },
    onClickButton: function (event) {
        let propagate = false;
        switch (event.currentTarget.dataset.action) {
        case 'contact':
            propagate = true;
            break;
        case 'menu':
            this.ui.mobileMenuContents.toggleClass('d-none');
            if (!this.ui.mobileMenuContents.hasClass('d-none')) {
                this.$el.addClass('menu-expanded');
                router.navigate('menu', {trigger: false}); // otherwise we can't select the menu item for the route we were on
                this.trigger('click:menu', true);
            } else {
                this.$el.removeClass('menu-expanded');
                this.trigger('click:menu', false);
            }
            break;
        case 'route':
            this.ui.mobileMenuContents.addClass('d-none');
            this.$el.removeClass('menu-expanded');
            router.navigate(event.currentTarget.hash, {trigger: true});
            break;
        default:
            this.ui.mobileMenuContents.addClass('d-none');
            this.$el.removeClass('menu-expanded');
            this.trigger('click:action', event.currentTarget.dataset.action);
            break;
        }
        if (event.currentTarget.parentElement.dataset.type === 'account') {
            this.ui.accountDropdown.toggleClass('show', false);
        }
        return propagate;
    },
    onRender: function () {
        this.ui.accountDropdownToggle.hover(function (event ) {
            this.ui.accountDropdown.toggleClass('show', event.type === 'mouseenter');
        }.bind(this));
    }
});
