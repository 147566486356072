module.exports={
  "v": "5.4.3",
  "fr": 29.9700012207031,
  "ip": 0,
  "op": 53947.0021973064,
  "w": 2450,
  "h": 2450,
  "nm": "Real-Spinning-Rings For Dev",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "5-Outline Outlines 2",
      "parent": 2,
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 105,
              "s": [
                0
              ],
              "e": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 120,
              "s": [
                100
              ],
              "e": [
                0
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 720,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      6.231
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      6.389,
                      -11.625
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      254.168,
                      0
                    ],
                    [
                      0,
                      361.615
                    ],
                    [
                      -362.195,
                      0
                    ],
                    [
                      -119.987,
                      -132.129
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.267,
                      0
                    ],
                    [
                      0,
                      20.752
                    ],
                    [
                      6.64,
                      6.777
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      214.56,
                      0
                    ],
                    [
                      0,
                      -403.23
                    ],
                    [
                      -403.877,
                      0
                    ],
                    [
                      -121.576,
                      234.793
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      -20.751
                    ],
                    [
                      -14.2,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -108.86,
                      211.075
                    ],
                    [
                      -362.195,
                      0
                    ],
                    [
                      0,
                      -361.616
                    ],
                    [
                      192.707,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.887,
                      7.854
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -10.237
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -133.773,
                      -146.947
                    ],
                    [
                      -403.877,
                      0
                    ],
                    [
                      0,
                      403.231
                    ],
                    [
                      282.996,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.686,
                      -5.173
                    ]
                  ],
                  "v": [
                    [
                      692.642,
                      317.426
                    ],
                    [
                      655.068,
                      279.853
                    ],
                    [
                      622.121,
                      299.358
                    ],
                    [
                      622.068,
                      299.329
                    ],
                    [
                      38.642,
                      654.763
                    ],
                    [
                      -617.171,
                      0
                    ],
                    [
                      38.642,
                      -654.763
                    ],
                    [
                      524.63,
                      -439.65
                    ],
                    [
                      524.814,
                      -439.82
                    ],
                    [
                      553.068,
                      -427
                    ],
                    [
                      590.642,
                      -464.574
                    ],
                    [
                      579.902,
                      -490.865
                    ],
                    [
                      579.952,
                      -490.911
                    ],
                    [
                      38.642,
                      -730.113
                    ],
                    [
                      -692.642,
                      0
                    ],
                    [
                      38.642,
                      730.113
                    ],
                    [
                      688.664,
                      334.827
                    ],
                    [
                      688.431,
                      334.703
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 7,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "ml2": {
                "a": 0,
                "k": 10,
                "ix": 8
              },
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  916.265,
                  954.903
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "5-Fill",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p667_1_0p333_0"
              ],
              "t": 138,
              "s": [
                0
              ],
              "e": [
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_1_0p333_0"
              ],
              "t": 60,
              "s": [
                0
              ],
              "e": [
                858
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_0p833_0p333_0"
              ],
              "t": 210,
              "s": [
                858
              ],
              "e": [
                858
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 211,
              "s": [
                858
              ],
              "e": [
                43338
              ]
            },
            {
              "t": 53946.0021972656
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1224.905,
            1224.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "n": [
                "0p667_1_0p333_0",
                "0p667_1_0p333_0",
                "0p667_1_0p333_0"
              ],
              "t": 60,
              "s": [
                0,
                0,
                100
              ],
              "e": [
                135,
                135,
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      6.231
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      6.389,
                      -11.625
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      254.168,
                      0
                    ],
                    [
                      0,
                      361.615
                    ],
                    [
                      -362.195,
                      0
                    ],
                    [
                      -119.987,
                      -132.129
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.267,
                      0
                    ],
                    [
                      0,
                      20.752
                    ],
                    [
                      6.64,
                      6.777
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      214.56,
                      0
                    ],
                    [
                      0,
                      -403.23
                    ],
                    [
                      -403.877,
                      0
                    ],
                    [
                      -121.576,
                      234.793
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      -20.751
                    ],
                    [
                      -14.2,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -108.86,
                      211.075
                    ],
                    [
                      -362.195,
                      0
                    ],
                    [
                      0,
                      -361.616
                    ],
                    [
                      192.707,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.887,
                      7.854
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -10.237
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -133.773,
                      -146.947
                    ],
                    [
                      -403.877,
                      0
                    ],
                    [
                      0,
                      403.231
                    ],
                    [
                      282.996,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.686,
                      -5.173
                    ]
                  ],
                  "v": [
                    [
                      692.642,
                      317.426
                    ],
                    [
                      655.068,
                      279.853
                    ],
                    [
                      622.121,
                      299.358
                    ],
                    [
                      622.068,
                      299.329
                    ],
                    [
                      38.642,
                      654.763
                    ],
                    [
                      -617.171,
                      0
                    ],
                    [
                      38.642,
                      -654.763
                    ],
                    [
                      524.63,
                      -439.65
                    ],
                    [
                      524.814,
                      -439.82
                    ],
                    [
                      553.068,
                      -427
                    ],
                    [
                      590.642,
                      -464.574
                    ],
                    [
                      579.902,
                      -490.865
                    ],
                    [
                      579.952,
                      -490.911
                    ],
                    [
                      38.642,
                      -730.113
                    ],
                    [
                      -692.642,
                      0
                    ],
                    [
                      38.642,
                      730.113
                    ],
                    [
                      688.664,
                      334.827
                    ],
                    [
                      688.431,
                      334.703
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  916.265,
                  954.903
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [
              1,
              1,
              1,
              1
            ],
            "ix": 4
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 5
          },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "4-Outline Outlines 2",
      "parent": 4,
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 129,
              "s": [
                0
              ],
              "e": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 138,
              "s": [
                100
              ],
              "e": [
                0
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      5.766
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      5.147,
                      -14.616
                    ],
                    [
                      191.994,
                      0
                    ],
                    [
                      0,
                      270.778
                    ],
                    [
                      -271.212,
                      0
                    ],
                    [
                      -89.63,
                      -95.829
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.744,
                      0
                    ],
                    [
                      0,
                      20.752
                    ],
                    [
                      7.895,
                      6.889
                    ],
                    [
                      162.925,
                      0
                    ],
                    [
                      0,
                      -312.325
                    ],
                    [
                      -312.826,
                      0
                    ],
                    [
                      -92.185,
                      187.61
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      -20.751
                    ],
                    [
                      -16.376,
                      0
                    ],
                    [
                      -80.744,
                      160.324
                    ],
                    [
                      -271.212,
                      0
                    ],
                    [
                      0,
                      -270.777
                    ],
                    [
                      141.686,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.89,
                      8.44
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -11.299
                    ],
                    [
                      -103.335,
                      -109.94
                    ],
                    [
                      -312.826,
                      0
                    ],
                    [
                      0,
                      312.325
                    ],
                    [
                      223.427,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.32,
                      -4.882
                    ]
                  ],
                  "v": [
                    [
                      539.211,
                      232.425
                    ],
                    [
                      501.637,
                      194.852
                    ],
                    [
                      466.193,
                      219.949
                    ],
                    [
                      27.211,
                      490.285
                    ],
                    [
                      -463.861,
                      0
                    ],
                    [
                      27.211,
                      -490.286
                    ],
                    [
                      386.197,
                      -334.549
                    ],
                    [
                      386.52,
                      -334.831
                    ],
                    [
                      415.637,
                      -321.001
                    ],
                    [
                      453.211,
                      -358.575
                    ],
                    [
                      440.321,
                      -386.889
                    ],
                    [
                      27.211,
                      -565.515
                    ],
                    [
                      -539.211,
                      0
                    ],
                    [
                      27.211,
                      565.515
                    ],
                    [
                      536.035,
                      248.752
                    ],
                    [
                      535.588,
                      248.534
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 7,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "ml2": {
                "a": 0,
                "k": 10,
                "ix": 8
              },
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  927.696,
                  954.903
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "4-Fill",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p667_1_0p333_0"
              ],
              "t": 138,
              "s": [
                0
              ],
              "e": [
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_1_0p333_0"
              ],
              "t": 60,
              "s": [
                0
              ],
              "e": [
                -812
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_0p833_0p333_0"
              ],
              "t": 210,
              "s": [
                -812
              ],
              "e": [
                -812
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 211,
              "s": [
                -812
              ],
              "e": [
                -54092
              ]
            },
            {
              "t": 53946.0021972656
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1224.905,
            1224.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "n": [
                "0p667_1_0p333_0",
                "0p667_1_0p333_0",
                "0p667_1_0p333_0"
              ],
              "t": 60,
              "s": [
                0,
                0,
                100
              ],
              "e": [
                135,
                135,
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      5.766
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      5.147,
                      -14.616
                    ],
                    [
                      191.994,
                      0
                    ],
                    [
                      0,
                      270.778
                    ],
                    [
                      -271.212,
                      0
                    ],
                    [
                      -89.63,
                      -95.829
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -11.744,
                      0
                    ],
                    [
                      0,
                      20.752
                    ],
                    [
                      7.895,
                      6.889
                    ],
                    [
                      162.925,
                      0
                    ],
                    [
                      0,
                      -312.325
                    ],
                    [
                      -312.826,
                      0
                    ],
                    [
                      -92.185,
                      187.61
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      -20.751
                    ],
                    [
                      -16.376,
                      0
                    ],
                    [
                      -80.744,
                      160.324
                    ],
                    [
                      -271.212,
                      0
                    ],
                    [
                      0,
                      -270.777
                    ],
                    [
                      141.686,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.89,
                      8.44
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -11.299
                    ],
                    [
                      -103.335,
                      -109.94
                    ],
                    [
                      -312.826,
                      0
                    ],
                    [
                      0,
                      312.325
                    ],
                    [
                      223.427,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.32,
                      -4.882
                    ]
                  ],
                  "v": [
                    [
                      539.211,
                      232.425
                    ],
                    [
                      501.637,
                      194.852
                    ],
                    [
                      466.193,
                      219.949
                    ],
                    [
                      27.211,
                      490.285
                    ],
                    [
                      -463.861,
                      0
                    ],
                    [
                      27.211,
                      -490.286
                    ],
                    [
                      386.197,
                      -334.549
                    ],
                    [
                      386.52,
                      -334.831
                    ],
                    [
                      415.637,
                      -321.001
                    ],
                    [
                      453.211,
                      -358.575
                    ],
                    [
                      440.321,
                      -386.889
                    ],
                    [
                      27.211,
                      -565.515
                    ],
                    [
                      -539.211,
                      0
                    ],
                    [
                      27.211,
                      565.515
                    ],
                    [
                      536.035,
                      248.752
                    ],
                    [
                      535.588,
                      248.534
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  927.696,
                  954.903
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [
              1,
              1,
              1,
              1
            ],
            "ix": 4
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 5
          },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "3-Outline 2",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 127,
              "s": [
                100
              ],
              "e": [
                0
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      20.752,
                      0
                    ],
                    [
                      6.768,
                      -6.58
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      90.245,
                      0
                    ],
                    [
                      0,
                      180.519
                    ],
                    [
                      -180.807,
                      0
                    ],
                    [
                      -59.67,
                      -62.929
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -10.816,
                      0
                    ],
                    [
                      0,
                      20.751
                    ],
                    [
                      7.93,
                      6.889
                    ],
                    [
                      113.923,
                      0
                    ],
                    [
                      0,
                      -222.066
                    ],
                    [
                      -222.422,
                      0
                    ],
                    [
                      -72.879,
                      72.763
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      10.006
                    ]
                  ],
                  "o": [
                    [
                      -10.185,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -59.22,
                      58.967
                    ],
                    [
                      -180.807,
                      0
                    ],
                    [
                      0,
                      -180.518
                    ],
                    [
                      93.647,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.856,
                      7.316
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -11.329
                    ],
                    [
                      -73.258,
                      -75.913
                    ],
                    [
                      -222.422,
                      0
                    ],
                    [
                      0,
                      222.067
                    ],
                    [
                      111.211,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.377,
                      -6.733
                    ],
                    [
                      0,
                      -20.751
                    ]
                  ],
                  "v": [
                    [
                      307.292,
                      220.853
                    ],
                    [
                      281.106,
                      231.493
                    ],
                    [
                      281.047,
                      231.434
                    ],
                    [
                      49.865,
                      326.858
                    ],
                    [
                      -277.516,
                      0
                    ],
                    [
                      49.865,
                      -326.857
                    ],
                    [
                      287.646,
                      -224.671
                    ],
                    [
                      287.876,
                      -224.893
                    ],
                    [
                      315.292,
                      -213
                    ],
                    [
                      352.865,
                      -250.573
                    ],
                    [
                      339.915,
                      -278.941
                    ],
                    [
                      49.865,
                      -402.086
                    ],
                    [
                      -352.865,
                      0
                    ],
                    [
                      49.865,
                      402.086
                    ],
                    [
                      334.639,
                      284.319
                    ],
                    [
                      334.57,
                      284.25
                    ],
                    [
                      344.865,
                      258.426
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 7,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "ml2": {
                "a": 0,
                "k": 10,
                "ix": 8
              },
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  905.041,
                  954.903
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "3-Fill",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  -41.333
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p667_-41p333_0p333_0"
              ],
              "t": 0,
              "s": [
                0
              ],
              "e": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0.277
                ]
              },
              "n": [
                "0p667_1_0p333_0p277"
              ],
              "t": 127,
              "s": [
                0
              ],
              "e": [
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_1_0p333_0"
              ],
              "t": 0,
              "s": [
                0
              ],
              "e": [
                840
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_0p833_0p333_0"
              ],
              "t": 210,
              "s": [
                840
              ],
              "e": [
                840
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 211,
              "s": [
                840
              ],
              "e": [
                43320
              ]
            },
            {
              "t": 53946.0021972656
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1224.905,
            1224.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "n": [
                "0p833_1_0p333_0",
                "0p833_1_0p333_0",
                "0p833_1_0p333_0"
              ],
              "t": 0,
              "s": [
                0,
                0,
                100
              ],
              "e": [
                135,
                135,
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      20.752,
                      0
                    ],
                    [
                      6.768,
                      -6.58
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      90.245,
                      0
                    ],
                    [
                      0,
                      180.519
                    ],
                    [
                      -180.807,
                      0
                    ],
                    [
                      -59.67,
                      -62.929
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -10.816,
                      0
                    ],
                    [
                      0,
                      20.751
                    ],
                    [
                      7.93,
                      6.889
                    ],
                    [
                      113.923,
                      0
                    ],
                    [
                      0,
                      -222.066
                    ],
                    [
                      -222.422,
                      0
                    ],
                    [
                      -72.879,
                      72.763
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      10.006
                    ]
                  ],
                  "o": [
                    [
                      -10.185,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -59.22,
                      58.967
                    ],
                    [
                      -180.807,
                      0
                    ],
                    [
                      0,
                      -180.518
                    ],
                    [
                      93.647,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.856,
                      7.316
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -11.329
                    ],
                    [
                      -73.258,
                      -75.913
                    ],
                    [
                      -222.422,
                      0
                    ],
                    [
                      0,
                      222.067
                    ],
                    [
                      111.211,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.377,
                      -6.733
                    ],
                    [
                      0,
                      -20.751
                    ]
                  ],
                  "v": [
                    [
                      307.292,
                      220.853
                    ],
                    [
                      281.106,
                      231.493
                    ],
                    [
                      281.047,
                      231.434
                    ],
                    [
                      49.865,
                      326.858
                    ],
                    [
                      -277.516,
                      0
                    ],
                    [
                      49.865,
                      -326.857
                    ],
                    [
                      287.646,
                      -224.671
                    ],
                    [
                      287.876,
                      -224.893
                    ],
                    [
                      315.292,
                      -213
                    ],
                    [
                      352.865,
                      -250.573
                    ],
                    [
                      339.915,
                      -278.941
                    ],
                    [
                      49.865,
                      -402.086
                    ],
                    [
                      -352.865,
                      0
                    ],
                    [
                      49.865,
                      402.086
                    ],
                    [
                      334.639,
                      284.319
                    ],
                    [
                      334.57,
                      284.25
                    ],
                    [
                      344.865,
                      258.426
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  905.041,
                  954.903
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [
              1,
              1,
              1,
              1
            ],
            "ix": 4
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 5
          },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }
      ],
      "ip": 127.000005172816,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "2-Outline Outlines 2",
      "parent": 8,
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 127,
              "s": [
                100
              ],
              "e": [
                0
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 360,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      1.649,
                      4.231
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      99.31,
                      0
                    ],
                    [
                      0,
                      -132.018
                    ],
                    [
                      -29.826,
                      -39.882
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -13.261,
                      0
                    ],
                    [
                      0,
                      20.752
                    ],
                    [
                      3.873,
                      5.91
                    ],
                    [
                      1.282,
                      1.425
                    ],
                    [
                      0,
                      36.442
                    ],
                    [
                      -90.403,
                      0
                    ],
                    [
                      -24.85,
                      -58.752
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -15.484,
                      0
                    ],
                    [
                      0,
                      20.751
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -36.105,
                      -86.204
                    ],
                    [
                      -132.018,
                      0
                    ],
                    [
                      0,
                      53.633
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.687,
                      10.375
                    ],
                    [
                      20.751,
                      0
                    ],
                    [
                      0,
                      -7.594
                    ],
                    [
                      -1.055,
                      -1.609
                    ],
                    [
                      -20.137,
                      -27.2
                    ],
                    [
                      0,
                      -90.404
                    ],
                    [
                      67.803,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      5.753,
                      13.376
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -4.815
                    ]
                  ],
                  "v": [
                    [
                      228.455,
                      -52.697
                    ],
                    [
                      228.6,
                      -52.756
                    ],
                    [
                      8.02,
                      -199.52
                    ],
                    [
                      -231.02,
                      39.52
                    ],
                    [
                      -183.531,
                      182.542
                    ],
                    [
                      -183.149,
                      182.269
                    ],
                    [
                      -151.553,
                      199.52
                    ],
                    [
                      -113.98,
                      161.946
                    ],
                    [
                      -120.106,
                      141.375
                    ],
                    [
                      -123.62,
                      136.819
                    ],
                    [
                      -155.671,
                      39.52
                    ],
                    [
                      8.02,
                      -124.171
                    ],
                    [
                      158.848,
                      -24.195
                    ],
                    [
                      158.913,
                      -24.222
                    ],
                    [
                      193.446,
                      -1.48
                    ],
                    [
                      231.02,
                      -39.053
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 7,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "ml2": {
                "a": 0,
                "k": 10,
                "ix": 8
              },
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  946.887,
                  915.383
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "2-Outline-Fill",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1.403
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p667_1p403_0p333_0"
              ],
              "t": 0,
              "s": [
                100
              ],
              "e": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0.297
                ]
              },
              "n": [
                "0p667_1_0p333_0p297"
              ],
              "t": 121,
              "s": [
                0
              ],
              "e": [
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_1_0p333_0"
              ],
              "t": 0,
              "s": [
                0
              ],
              "e": [
                -814
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_0p833_0p333_0"
              ],
              "t": 210,
              "s": [
                -814
              ],
              "e": [
                -814
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "n": [
                "0p833_0p833_0p167_0p167"
              ],
              "t": 211,
              "s": [
                -814
              ],
              "e": [
                -54094
              ]
            },
            {
              "t": 53946.0021972656
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1224.905,
            1224.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "n": [
                "0p833_1_0p333_0",
                "0p833_1_0p333_0",
                "0p833_1_0p333_0"
              ],
              "t": 0,
              "s": [
                0,
                0,
                100
              ],
              "e": [
                135,
                135,
                100
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      1.649,
                      4.231
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      99.31,
                      0
                    ],
                    [
                      0,
                      -132.018
                    ],
                    [
                      -29.826,
                      -39.882
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -13.261,
                      0
                    ],
                    [
                      0,
                      20.752
                    ],
                    [
                      3.873,
                      5.91
                    ],
                    [
                      1.282,
                      1.425
                    ],
                    [
                      0,
                      36.442
                    ],
                    [
                      -90.403,
                      0
                    ],
                    [
                      -24.85,
                      -58.752
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -15.484,
                      0
                    ],
                    [
                      0,
                      20.751
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -36.105,
                      -86.204
                    ],
                    [
                      -132.018,
                      0
                    ],
                    [
                      0,
                      53.633
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      6.687,
                      10.375
                    ],
                    [
                      20.751,
                      0
                    ],
                    [
                      0,
                      -7.594
                    ],
                    [
                      -1.055,
                      -1.609
                    ],
                    [
                      -20.137,
                      -27.2
                    ],
                    [
                      0,
                      -90.404
                    ],
                    [
                      67.803,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      5.753,
                      13.376
                    ],
                    [
                      20.752,
                      0
                    ],
                    [
                      0,
                      -4.815
                    ]
                  ],
                  "v": [
                    [
                      228.455,
                      -52.697
                    ],
                    [
                      228.6,
                      -52.756
                    ],
                    [
                      8.02,
                      -199.52
                    ],
                    [
                      -231.02,
                      39.52
                    ],
                    [
                      -183.531,
                      182.542
                    ],
                    [
                      -183.149,
                      182.269
                    ],
                    [
                      -151.553,
                      199.52
                    ],
                    [
                      -113.98,
                      161.946
                    ],
                    [
                      -120.106,
                      141.375
                    ],
                    [
                      -123.62,
                      136.819
                    ],
                    [
                      -155.671,
                      39.52
                    ],
                    [
                      8.02,
                      -124.171
                    ],
                    [
                      158.848,
                      -24.195
                    ],
                    [
                      158.913,
                      -24.222
                    ],
                    [
                      193.446,
                      -1.48
                    ],
                    [
                      231.02,
                      -39.053
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  946.887,
                  915.383
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "fl",
          "c": {
            "a": 0,
            "k": [
              1,
              1,
              1,
              1
            ],
            "ix": 4
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 5
          },
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }
      ],
      "ip": 121.000004928431,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "1-Circle-Outline Outlines",
      "parent": 11,
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p667_1_0p333_0"
              ],
              "t": 77,
              "s": [
                100
              ],
              "e": [
                0
              ]
            },
            {
              "t": 210.000008553475
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      -39.613
                    ],
                    [
                      39.613,
                      0
                    ],
                    [
                      0,
                      39.612
                    ],
                    [
                      -39.612,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      39.612
                    ],
                    [
                      -39.612,
                      0
                    ],
                    [
                      0,
                      -39.613
                    ],
                    [
                      39.613,
                      0
                    ]
                  ],
                  "v": [
                    [
                      71.726,
                      -0.001
                    ],
                    [
                      0,
                      71.726
                    ],
                    [
                      -71.726,
                      -0.001
                    ],
                    [
                      0,
                      -71.726
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 7,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "ml2": {
                "a": 0,
                "k": 10,
                "ix": 8
              },
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  954.907,
                  954.904
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "nm": "1-circle-Fill 2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 0,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1224.905,
            1224.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "n": [
                "0p667_1_0p333_0",
                "0p667_1_0p333_0",
                "0p667_1_0p333_0"
              ],
              "t": 0,
              "s": [
                0,
                0,
                100
              ],
              "e": [
                100,
                100,
                100
              ]
            },
            {
              "t": 150.000006109625
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      -39.613
                    ],
                    [
                      39.613,
                      0
                    ],
                    [
                      0,
                      39.612
                    ],
                    [
                      -39.612,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      39.612
                    ],
                    [
                      -39.612,
                      0
                    ],
                    [
                      0,
                      -39.613
                    ],
                    [
                      39.613,
                      0
                    ]
                  ],
                  "v": [
                    [
                      71.727,
                      -0.001
                    ],
                    [
                      0.001,
                      71.725
                    ],
                    [
                      -71.724,
                      -0.001
                    ],
                    [
                      0.001,
                      -71.726
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  954.906,
                  954.904
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 4,
      "nm": "1-circle-Fill ",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0.188
                ]
              },
              "n": [
                "0p667_1_0p333_0p188"
              ],
              "t": 90,
              "s": [
                0
              ],
              "e": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "n": [
                "0p833_1_0p333_0"
              ],
              "t": 138,
              "s": [
                100
              ],
              "e": [
                0
              ]
            },
            {
              "t": 150.000006109625
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1224.905,
            1224.905,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            954.905,
            954.905,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "n": [
                "0p667_1_0p333_0",
                "0p667_1_0p333_0",
                "0p667_1_0p333_0"
              ],
              "t": 0,
              "s": [
                0,
                0,
                100
              ],
              "e": [
                100,
                100,
                100
              ]
            },
            {
              "t": 150.000006109625
            }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      -39.613
                    ],
                    [
                      39.613,
                      0
                    ],
                    [
                      0,
                      39.612
                    ],
                    [
                      -39.612,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      39.612
                    ],
                    [
                      -39.612,
                      0
                    ],
                    [
                      0,
                      -39.613
                    ],
                    [
                      39.613,
                      0
                    ]
                  ],
                  "v": [
                    [
                      71.727,
                      -0.001
                    ],
                    [
                      0.001,
                      71.725
                    ],
                    [
                      -71.724,
                      -0.001
                    ],
                    [
                      0.001,
                      -71.726
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  954.906,
                  954.904
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 56645.0023071981,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
