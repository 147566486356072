'use strict';

const bodymovin = require('bodymovin'),
    Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    //
    About = require('./about'),
    Account = require('./account'),
    Compensation = require('./compensation'),
    Header = require('./header'),
    LapsedSubscription = require('./lapsedSubscription'),
    Legal = require('./legal'),
    LogIn = require('./logIn'),
    Password = require('./password'),
    Privacy = require('./privacy'),
    Register = require('./register'),
    Resources = require('./resources'),
    TermsOfUse = require('./termsOfUse'),
    Upgrade = require('./upgrade'),
    //
    animationData = require('../../register/json/animationData.json'),
    configuration = require('../../common/configuration'),
    enumerations = require('../../common/enumerations').enumerations,
    router = require('../utilities/router'),
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'container'
    },
    childViewEvents: {
        'click:action': 'onClickAction',
        'click:menu':   'onClickMenu',
        'click:renew':  'onClickRenew'
    },
    regions: {
        about:              {el: 'div[data-region=about]',               replaceElement: true},
        account:            {el: 'div[data-region=account]',             replaceElement: true},
        compensation:       {el: 'div[data-region=compensation]',        replaceElement: true},
        header:             {el: 'div[data-region=header]',              replaceElement: true},
        lapsedSubscription: {el: 'div[data-region=lapsedSubscription]',  replaceElement: true},
        legal:              {el: 'div[data-region=legal]',               replaceElement: true},
        login:              {el: 'div[data-region=login]',               replaceElement: true},
        password:           {el: 'div[data-region=password]',            replaceElement: true},
        privacy:            {el: 'div[data-region=privacy]',             replaceElement: true},
        register:           {el: 'div[data-region=register]',            replaceElement: true},
        resources:          {el: 'div[data-region=resources]',           replaceElement: true},
        sidebar:            {el: 'div[data-region=sidebar]',             replaceElement: true},
        termsOfUse:         {el: 'div[data-region=termsOfUse]',          replaceElement: true},
        upgrade:            {el: 'div[data-region=upgrade]',             replaceElement: true}
    },
    content: {
        about: About,
        account: Account,
        compensation: Compensation,
        legal: Legal,
        login: LogIn,
        password: Password,
        privacy: Privacy,
        register: Register,
        resources: Resources,
        termsOfUse: TermsOfUse,
        upgrade: Upgrade
    },
    currentRoute: '',
    tagName: 'div',
    template: templates.container,
    initialize: function () {
        this.listenTo(router, 'newRoute', this.onNewRoute);
        this.listenTo(user, 'change:id', function (model, id) {
            // console.log('container - user.change:id');
            if (!id) {
                // window.location.hash = 'login';
                router.navigate('/login/',  {trigger: true});
            } else {
                // window.location.hash = user.hasConcierge() ? 'concierge' : 'dashboard';
                router.navigate(user.hasConcierge() ? '/concierge/' : '/dashboard/',  {trigger: true});
            }
            this.updateConciergeDashboardClass();
        });
        this.listenTo(user, 'change:subscriptionStatus', function () {
            // console.log('container - user.change:subscriptionStatus');
            if (user.get('subscriptionStatus') === 'lapsed') {
                if (!this.lapsedSubscription) {
                    this.lapsedSubscription = this.showChildView('lapsedSubscription', new LapsedSubscription({subscriptionPrice: user.get('subscriptionPrice') || '_missing_price_'}));
                }
                this.lapsedSubscription.open();
            }
        });
        if (configuration.showTestTips) {
            this.listenTo(user, 'change:product change:jobId change:mode', function () {
                // console.log('container.user.change:*');
                this.updateTestTips();
            }.bind(this));
        }
        $(window).on('resize', _.debounce(function () {
            this.updateMobileDesktopClass();
        }.bind(this)));
        // $(document).on('click', 'a[href^="/"', function (event) {
        $(document).on('click', 'a[href^="/"]', function (event) {
            event.preventDefault();
            router.navigate(this.pathname,  {trigger: true});
            return false;
        });
        router.start();
    },
    isMobile: function () {
        return $(window).width() <= configuration.mobileWidth;
    },
    onAttach: function () {
        this.animation = bodymovin.loadAnimation({
            container: document.getElementById('animation'),
            renderer: 'svg',
            loop: true,
            autoplay: configuration.animation ? configuration.animation.autoplay : true,
            animationData: animationData
        });
        this.updateMobileDesktopClass();
        console.timeEnd('start');
    },
    onClickAction: function (action) {
        switch(action) {
        case 'logout':
            user.deauthenticate();
            break;
        default:
            console.log('container.onClickAction: %s', action);
            break;
        }
    },
    onClickMenu: function (showMenu) {
        // console.log(showMenu);
        $('body').toggleClass('menu', showMenu);
        _.each(this.content, function (view, region) {
            const v = this.getChildView(region);
            if (v) {
                if (showMenu) {
                    if (!v.$el.hasClass('hidden')) {
                        this.priorRegion = region;
                        // console.log(this.priorRegion);
                        v.$el.addClass('hidden');
                    }
                } else {
                    v.$el.toggleClass('hidden', this.priorRegion !== region);
                }
            }
        }.bind(this));
    },
    onClickRenew: function () {
        router.navigate('/upgrade/', {trigger: true});
    },
    onRender: function () {
        $('footer').html(templates.footer());
        this.showChildView('header', new Header());
        this.showChildView('compensation', new Compensation());
        this.updateConciergeDashboardClass();
        this.updateTestTips();
    },
    onNewRoute: function (route) {
        const darkModeRoutes = {
            'login/': true,
            'password/': true,
            'register/': true,
            'upgrade/': true
        };
        let section = '';
        if (route === 'login/') {
            user.deauthenticate({silent: true});
        }
        if (route === 'logout/' || (_.includes(['compensation', 'account'], route) && !user.id)) {
            // window.location.hash = 'login';
            route = 'login/';
            router.navigate('/login/', {trigger: false});
            user.deauthenticate();
        }
        if (_.includes(route, 'resources')) {
            section = route.split('/')[1];
            route = 'resources/';
        }
        const darkMode = darkModeRoutes[route] ? true : false;
        $('body').removeClass('form-big');
        $('body').removeClass(this.currentRoute + ' dark-mode light-mode');
        $('body').addClass(route + (darkMode ? ' dark-mode' : ' light-mode'));
        if (this.animation) {
            this.animation[darkMode && configuration.animation.autoplay ? 'play' : 'stop']();
        }
        this.currentRoute = route;
        _.each(this.content, function (view, region) {
            const v = this.getChildView(region);
            if (region === _.camelCase(route.slice(0, -1))) {
                if (v) {
                    v.$el.toggleClass('hidden', false);
                    if (route === 'upgrade/') {
                        v.render();
                    }
                    if (route === 'resources/') {
                        v.showSection(section);
                    }
                } else {
                    this.showChildView(region, new view());
                }
            } else {
                if (v) {
                    v.$el.toggleClass('hidden', true);
                    if (region === 'account' || region === 'register') {
                        v.destroy();
                    }
                }
            }
        }.bind(this));
        if (route === 'privacy/' || route === 'terms-of-use/') {
            window.scrollTo(0, 0);
        }
    },
    updateMobileDesktopClass: function () {
        const isMobile = $('#mobileSpy').is(':visible');
        $('body').toggleClass('mobile', isMobile).toggleClass('desktop', !isMobile);
    },
    updateConciergeDashboardClass: function () {
        const hasConcierge = user.hasConcierge();
        $('body').toggleClass('concierge', hasConcierge).toggleClass('dashboard', !hasConcierge);
    },
    updateTestTips: function () {
        // console.log('container - user.change:product|jobId');
        const product = user.get('product') || 'no product',
            jobId = user.get('jobId'),
            job = jobId ? enumerations.jobIds[jobId].toLowerCase() : 'no job';
        this.getChildView('header').$el.find('[data-name=testTips]').html(templates.testTips({
            product: product,
            job: job,
            mode: user.get('mode')
        }));
    }
});
