'use strict';

const Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    // configuration = require('../../common/configuration'),
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'registerForm'
    },
    tagName: 'div',
    template: templates.registerAccountForm,
    //
    onRender: function () {
        this.$el.find('select').each(function (index, select) {
            $(select).select2({placeholder: select.dataset.label, width: '100%'});
        });
    },
    templateContext: function () {
        this.options[this.options.product] = true;
        return _.extend({singlePage: true}, this.options);
    }
});
