'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    Reports = require('../collections/reports'),
    //
    Report = require('./report'),
    //
    router = require('../utilities/router'),
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'sidebar col-12 col-md-3'
    },
    regions: {
        report: {el: '[data-region=report]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.sidebar,
    templateContext: function () {
        const o = {};
        o[this.route] = true;
        return o;
    },
    initialize: function () {
        this.reports = new Reports([], {noInitialFetch: true, via: 'sidebar.' + this.cid});
        this.listenTo(this.reports, 'sync', function () {
            console.log('sidebar - reports.sync');
            this.render();
        }.bind(this));
        this.listenTo(user, 'change:mode change:jobId change:compensationTypeId', _.debounce(function () {
            // console.log('sidebar - user.change:mode|jobId');
            this.reports.refresh();
        }.bind(this)));
        this.listenTo(user, 'change:mode', function (model, mode) {
            // console.log('sidebar - user.change:mode');
            if (mode) {
                this.render();
            } // @acavan! should we clear the exiting compensation content?
        });
        router.listenTo(router, 'newRoute', function (route) {
            // console.log('sidebar - router.newRoute');
            if (this.initialized) {
                this.route = route;
                this.render();
            }
        }.bind(this));
        this.route = router.currentRoute();
        this.initialized = true;
    },
    onRender: function () {
        let model;
        if (this.route === 'compensation') {
            model = this.reports.userReport();
            // model = report;
            if (model) {
                this.showChildView('report', new Report({model: model, class: 'sticky-top', mobile: false}));
            }
        }
    }
});
