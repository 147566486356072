'use strict';

const Backbone = require('backbone'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    configuration = require('../../common/configuration'),
    enumerations = require('../../common/enumerations').enumerations,
    whom = 'subscription';

module.exports = Backbone.Model.extend({
    fetch: function (options, callback) { // options - dataSetId, jobId, headers
        let url;
        if (!configuration.mockdata.subscriptions) {
            url = configuration.apiUrlBase + '/subscriptions?all=true';
            // console.log(url);
        } else {
            // @acavan! - touch up when/if needed
            console.error('subscriptions.fetch - trying to get mockdata but there\'s no code yet...');
            // url = configuration.cdnUrlBase + '/mockdata/subscriptions_' + user.product() + '_' + (user.hasConcierge() ? 'paid' : 'gratis') + '.json';
        }
        $.ajax({
            contentType: 'application/json',
            dataType: 'json',
            headers: options.headers,
            method: 'GET',
            url: url
        }).done(function (data/*, statusText, xhr*/) {
            this.set(this.parse(data, options));
            if (callback) {
                callback(null, this.toJSON());
            }
        }.bind(this)).fail(function (xhr/*, errorText, error*/) {
            console.error('subscriptions.fetch.fail');
            console.log(xhr.responseJSON);
            if (callback) {
                callback(xhr.responseJSON.error || 'subscriptionFetchFailed');
            }
        });
    },
    initialize: function () {
        this.set({
            subscriptions: [],
            subscriptionStatus: 'none', // none | active | lapsed
            subscriptionCents: -1,
            subscriptionPrice: -0.01
        });
    },
    parse: function (subscriptions, options) {
        const o = {
            subscriptions: {}
        };
        _.each(subscriptions, function (subscription) {
            o.subscriptions[subscription.jobId] = subscription;
            o.subscriptions[subscription.jobId].job = enumerations.jobIds[subscription.jobId]; // @acavan! should this be removed at some point?
        });
        o.subscriptionStatus = (function () {
            if (!subscriptions || !o.subscriptions[options.jobId]) {
                return 'none';
            }
            return new Date(o.subscriptions[options.jobId].expiration) < new Date() ? 'lapsed' : 'active';
        })();
        o.subscriptionCents = enumerations.subscriptionPrices.dataSetIds[options.dataSetId].jobIds[options.jobId] || 1;
        o.subscriptionPrice = (o.subscriptionCents / 100).toFixed(2);
        // console.log('subscriptions.parse', _.map(o.subscriptions, function (s) {return s.job + ', ' + s.expiration.substr(0, 10);}).sort());
        return o;
    },
    purchase: function (intent, callback) {
        console.log('subscription.create', intent, callback);
    },
    whom: function () {
        console.log(whom);
    }
});
