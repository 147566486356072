'use strict';
const routes = {
        'about/': 'any',
        'account/': 'authenticated',
        'compensation/': 'authenticated',
        'concierge/': 'authenticated',
        'dashboard/': 'authenticated',
        'legal/': 'guest',
        'login/': 'guest',
        'loginViaPassword/': 'guest',
        'logout/': 'authenticated',
        'password/': 'guest',
        'privacy/': 'guest',
        'register/': 'guest',
        'resources/(:section)/': 'any',
        'terms-of-use/': 'guest',
        'upgrade/': 'dashboard'
    },
    Backbone = require('backbone'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    configuration = require('../../common/configuration'),
    //
    Router = Backbone.Router.extend({
        theCurrentRoute: null,
        initialize: function () {
            // const hash = window.location.hash.substr(1);
            const pathname = window.location.pathname.substr(1);
            _.each(routes, function (conditions, route) {
                // configure such that all the routes call onNewRoute...
                this.route(route, 'onNewRoute');
            }.bind(this));
            // this.initialHash = routes[hash] ? hash : configuration.defaultRoute;
            // this.initialRoute = 'register/';
            this.initialRoute = routes[pathname] || pathname.includes('resources/') ? pathname : configuration.defaultRoute;
        },
        start: function () {
            // let hash;
            // initial hashes that require authentication
            //      account, compensation, concierge, and dashboard
            // initial hashes that could be shown if the user is not athenticated
            //      about, login, register, and upgrade
            // initial hashes that shouldn't be shown if authenticated
            //      upgrade
            // const condition = routes[this.initialHash];
            let condition = routes[this.initialRoute],
                search = '';
            if (!condition && this.initialRoute.indexOf('resources/') === 0) {
                condition = 'any';
            }
            switch (condition) {
            case 'any':
            case 'guest':
                // noop
                break;
            case 'authenticated':
                if (!user.authenticated || !user.authenticated()) {
                    this.initialRoute = configuration.defaultRoute;
                }
                break;
            case 'dashboard':
                if (!user.authenticated || !user.authenticated()) {
                    this.initialRoute = configuration.defaultRoute;
                } else if (user.mode() === 'concierge') {
                    this.initialRoute = 'concierge/';
                }
                break;
            default:
                console.error('router.start - unknown condition (%s)', condition);
                break;
            }
            if (this.initialRoute === 'password/') {
                search = window.location.search;
            }
            if (this.initialRoute === 'login/' && window.location.search) {
                alert(_.capitalize(_.snakeCase(window.location.search).replace(/_/g, ' ')));
            }
            this.navigate(this.initialRoute + search, {trigger: true});
            this.onNewRoute();
        },
        currentRoute: function () {
            return this.theCurrentRoute; // eslint-disable-line no-underscore-dangle
        },
        onNewRoute: function () {
            const aliases = {
                    '/concierge/': 'compensation/',
                    '/dashboard/': 'compensation/'
                },
                // hash = window.location.hash.substr(1);
                route = window.location.pathname;
            if (route === '/loginViaPassword/') {
                // for now we just hammer the url and cause a refresh to clear the token...
                /// @acavan! - need to revisit this... e.g., what should we do if they click away from the password view?
                // window.location.href = window.location.origin + window.location.pathname + '/login/';
                this.navigate('login/', {trigger: true});
                return;
            }
            document.title = _.startCase(route).replace('Resources', 'Resources: ').replace('Complanders', 'Complander\'s').replace('Login', 'Log In') + ' - Complander';
            $('meta[property="og:title"]').attr('content', document.title);
            this.theCurrentRoute = aliases[route] || route.substr(1);
            this.trigger('newRoute', this.theCurrentRoute);
        }
    });
module.exports = new Router();
