module.exports={
    "animation": {
        "autoplay": true
    },
    "apiUrlBase": "https://staging.api.jthelander.com/complander",
    "apiVersion": "",
    "assetsUrlBase": "https://staging.complander.com/20240315_2117_da55140aa5c5dd13bae93419dff10aca819f3055/mothership/assets",
    "blurString": "$◼︎◼︎◼︎",
    "cdnUrlBase": "https://staging.complander.com/20240315_2117_da55140aa5c5dd13bae93419dff10aca819f3055",
    "mobileWidth": 767,
    "mockdata": {
    },
    "showTestTips": true
}
