'use strict';

const Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    // _ = require('lodash'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        // class: 'navigation-desktop navigation'
        class: 'navigation'
    },
    discardOneScroll: false,
    events: {
        'click li': 'onClickPageIndicator'
    },
    pageCount: 0,
    pageNumber: 1,
    pageOffsets: [],
    tagName: 'div',
    template: templates.navigation,
    templateContext: function () {
        return {
            pageCount: this.pageCount,
            mobile: this.mobile
        };
    },
    ui: {
        selectors:     'li',
        pageNumber:     'span[data-name=pageNumber]',
        pageCount:      'span[data-name=pageCount]',
        navigation:     'div[data-name=navigation]'
    },
    formatPageNumber: function (pageNumber) {
        return ('00' + pageNumber).substr(-2);
    },
    initialize: function () {
        this.mobile = this.options.mode === 'mobile';
        this.via = this.options.via;
        this.$el.addClass(this.mobile ? 'mobile' + (this.via === 'register' ? ' row' : '') : 'desktop');
    },
    /*
    @acavan! - restore?
    initialize: function () {
        $(window).scroll(_.debounce(function () {
            const top = $(window).scrollTop(),
                pageNumber = _.findIndex(this.pageOffsets, function (offset) {
                    return offset > top;
                }) + 1;
            if (!this.discardOneScroll) {
                this.updatePageNumber(pageNumber);
                // console.log(pageNumber);
            } else {
                this.discardOneScroll = false;
            }
        }.bind(this), 100));
    },
    */
    onClickPageIndicator: function (event) {
        const pageNumber = +event.currentTarget.dataset.page;
        if (pageNumber <= this.pageNumber) {
            this.pageNumber = pageNumber;
            this.ui.selectors.each(function (index, selector) {
                $(selector)
                    .toggleClass('selected', +selector.dataset.page === pageNumber)
                    .toggleClass('disabled', +selector.dataset.page > pageNumber)
                    .toggleClass('visited', +selector.dataset.page < pageNumber);
            });
            this.ui.pageNumber.text(this.formatPageNumber(this.pageNumber));
            this.trigger('change:pageNumber', this.pageNumber);
        }
        /*
        @acavan! - restore?
        this.discardOneScroll = true;
        window.location.hash = '#p' + this.formatPageNumber(pageNumber);
        */
        return false;
    },
    updatePageCount: function (pageCount) {
        this.pageCount = pageCount;
        this.render();
    },
    updatePageNumber: function (pageNumber) {
        this.pageNumber = pageNumber;
        if (!this.mobile) {
            this.ui.navigation.toggleClass('hidden', !pageNumber || pageNumber > this.pageCount); // hide if no pageNumber (or zero...)
        }
        this.$el.find('li[data-page=' + pageNumber + ']').trigger('click');
    }
});
