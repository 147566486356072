'use strict';

const analytics = require('universal-ga'),
    Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    // _ = require('lodash'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'about col-12'
    },
    tagName: 'div',
    template: templates.about,
    //
    onRender: function () {
        analytics.pageview('about');
    }
});
