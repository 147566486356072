'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    // _ = require('lodash'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'table'
    },
    tagName: 'div',
    //
    initialize: function () {
        this.template = this.options.mobile ? templates.tableMobile : templates.table;
        this.templateContext = {
            title: this.model.get('title'),
            columnHeaders: this.model.columnHeaders(),
            rows: this.model.rows(),
            upgradable: this.options.upgradable
        };
        this.templateContext.columnCount = this.templateContext.rows[0].values.length + 1;
    },
    onRender: function () {
        this.$el.find('table').removeClass('d-none');
        this.$el.find('[data-name=pending').addClass('d-none');
    },
    pending: function () {
        this.$el.find('table').addClass('d-none');
        this.$el.find('[data-name=pending').removeClass('d-none');
    }
});
