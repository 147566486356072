'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    // _ = require('lodash'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'table'
    },
    tagName: 'div',
    template: templates.chart,
    //
    initialize: function () {
        this.templateContext = {
            title: this.model.get('title')
        };
    }
});
