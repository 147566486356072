'use strict';

const Backbone = require('backbone'),
    // BackboneRadio = require('backbone.radio'),
    $ = require('jquery'),
    // _ = require('lodash'),
    //
    configuration = require('../../common/configuration'),
    user = require('../models/user'),
    whom = 'intent';

module.exports = Backbone.Model.extend({
    create: function ({dataSetId, jobId, price, coupon}, callback) {
        // @acavan - can we memoize this?
        let url = [
            configuration.apiUrlBase + '/subscriptions/intent/job/',
            jobId,
            '/dataSet/',
            dataSetId,
            '/price/',
            price
        ].join('');
        if (coupon) {
            url += '?coupon=' + coupon;
        }
        console.log('intent.create - url:%s', url);
        $.ajax({
            dataType: 'json',
            headers: user.authorizationHeader(),
            method: 'POST',
            url: url
        }).done(function (data, statusText, xhr) { // eslint-disable-line no-unused-vars
            // console.log(data);
            this.set(data);
            callback(null);
        }.bind(this)).fail(function (xhr) { // eslint-disable-line no-unused-vars
            const error = xhr.responseJSON.error || 'error',
                description = xhr.responseJSON.description || error;
            console.error('intent.create.fail - error:%s (%s)', error, description);
            callback({error: error, description: description});
        }.bind(this));
    },
    whom: function () {
        console.log(whom);
    }
});
