'use strict';

const analytics = require('universal-ga'),
    async = require('async'),
    Backbone = require('backbone'),
    // BackboneRadio = require('backbone.radio'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    Subscriptions = require('../models/subscriptions'),
    configuration = require('../../common/configuration'),
    cookies = require('../utilities/cookies'),
    router = require('../utilities/router'),
    enumerations = require('../../common/enumerations').enumerations,
    //
    User = Backbone.Model.extend({
        url: configuration.apiUrlBase + '/users',
        waitees: [],
        stashedPassword: '',
        //
        authenticate: function (options) {
            configuration.mockdata.user = configuration.mockdata.user || (options.mockUser === 'on' ? true : false);
            configuration.mockdata.reports = configuration.mockdata.reports || (options.mockCompensation === 'on' ? true : false);
            this.fetch(options);
        },
        authenticated: function () {
            return this.id ? true : false;
        },
        authorizationHeader: function (email, password) {
            email = email || this.attributes.email;
            password = password || this.stashedPassword;
            // currently we send 'missingEmailOrPassword' if either missing
            // shouldn't affect `POST/users` but would an empty header string be better?
            return ({'Authorization': 'Basic ' + ((email && password) ? btoa(email + ':' + password) : 'missingEmailOrPassword')});
        },
        create: function (serializedArray) {
            this.persist(serializedArray, true);
        },
        deauthenticate: function (options) {
            options = options || {};
            this.clear({silent: options.silent ? true : false});
            cookies.removeItem('credentials');
        },
        fetch: function (options) {
            const predefinedUsers = configuration.predefinedUsers;
            let url, headers = '';
            this.unset('error', {silent: true});
            if (!options.upgrade) {
                if (configuration.mockdata.user) {
                    url = configuration.cdnUrlBase + '/mockdata/user_' + options.product + '.json';
                } else {
                    url = this.url + '/self';
                    if (!options.email || !options.password) {
                        options.email = predefinedUsers[options.product].email;
                        options.password = predefinedUsers[options.product].password;
                    }
                }
            }
            headers = this.authorizationHeader(options.email, options.password);
            async.waterfall([
                function (callback) {
                    $.ajax({
                        dataType: 'json',
                        headers: headers,
                        method: 'GET',
                        url: url
                    }).done(function (data, statusText, xhr) { // eslint-disable-line no-unused-vars
                        callback(null, {
                            password: options.password,
                            user: data
                        });
                    }).fail(function (xhr, errorText, error) { // eslint-disable-line no-unused-vars
                        console.log('user.fetch.authentication.fail');
                        console.log(xhr.responseJSON);
                        xhr.responseJSON = xhr.responseJSON || {};
                        callback(xhr.responseJSON.error || 'authenticationFailed');
                    });
                },
                function (results, callback) {
                    // attach the (presumably) empty subscriptions for now and then request them..
                    // results.user = _.extend(results.user, this.subscriptions.toJSON());
                    this.subscriptions.fetch({dataSetId: results.user.dataSetId, jobId: results.user.jobId, headers: headers}, function (error, data) {
                        results.user = _.extend(results.user, data);
                        callback(error, results);
                    });
                }.bind(this)
            ], function (error, results) {
                if (!error) {
                    this.stashedPassword = results.password;
                    if (options.compensationAudit === 'on') {
                        results.user.compensationAudit = true;
                    }
                    this.set(this.parse(results.user, options.gratis === 'on'));
                    // console.log('user.fetch.fini');
                } else {
                    console.log('user.fetch.error (%s)', error);
                    this.set({error: error});
                }
            }.bind(this));
        },
        hasConcierge: function () {
            return this.attributes.mode === 'concierge';
        },
        initialize: function () {
            const credentials = cookies.getItem('credentials'),
                fini = function () {
                    this.initialized = true;
                    _.each(this.waitees, function (waitee) {
                        waitee(null);
                    });
                }.bind(this);
            if (credentials) {
                this.authenticate({credentials: credentials, callback: fini});
            } else {
                fini();
            }
            this.subscriptions = new Subscriptions();
            this.listenTo(this.subscriptions, 'change', function (model) {
                const mode = model.get('subscriptionStatus') !== 'active' ? 'dashboard' : 'concierge';
                this.set(_.extend({mode: mode}, model.toJSON()));
            }.bind(this));
        },
        mode: function () {
            return this.attributes.mode; // dashboard or concierge
        },
        password: function () {
            return this.stashedPassword;
        },
        parse: function (user, forceGratis) {
            if (!_.includes(enumerations.regionIds, user.regionId)) {
                // @acavan! for now make any unrecognized regionId the united states...
                user.regionId = enumerations.regionNames['United States'];
            }
            user.product = enumerations.products[user.productId].product;
            // console.log('user.parse - subscriptionStatus:%s', user.subscriptionStatus);
            user.mode = user.subscriptionStatus !== 'active' || forceGratis ? 'dashboard' : 'concierge';
            user.basePayIsSignOnId = user.basePayIsSignOn ? 1 : 0;
            user.signOnBonusId = user.signOnBonus ? 1 : 0;
            user.projectedBonusId = user.targetBonus ? 1 : 0;
            user[enumerations.compensationTypeIds[user.compensationTypeId].toLowerCase()] = true;
            return user;
        },
        persist: function (serializedArray, isCreate) {
            const o = {},
                arrayTypes = {
                    investmentAreaId: true
                },
                cullableNames = {
                    projectedBonusId: true,
                    signOnBonusId: true
                },
                nullIfZero = {
                    projectedBonusId: 'projectedBonus',
                    signOnBonusId: 'signOnBonus',
                    basePayIsSignOnId: 'basePayIsSignOn'
                },
                cullableValues = {
                    doNotKnowValue: true,
                    noChoiceValue: true
                },
                nonIdNumerics = {
                    averageDailyCommuteTimeMinutes: true,
                    averageMonthlyCommuteCost: true,
                    basePay: true,
                    bonus: true,
                    bonusAmount: true,
                    equityReceived: true,
                    hourlyRate: true,
                    hoursPerWeek: true,
                    monthlyRent: true,
                    mostRecentBonus: true,
                    percentCarriedInterest: true,
                    projectedBonus: true,
                    projectedBonusAmount: true,
                    signOnBonus: true,
                    basePayIsSignOn: true,
                    studentDebtAmount: true,
                    studentLoanMonthlyPayment: true,
                    totalCash: true
                },
                numberify = function (name, value) {
                    if (name.substr(-2) === 'Id' || nonIdNumerics[name]) {
                        value = +value;
                    }
                    return value;
                };
            _.each(serializedArray, function (nameValue) {
                if (nameValue.name === 'password') {
                    if (nameValue.value === this.stashedPassword) {
                        return;
                    }
                }
                if (nameValue.name === 'compensationTypeId' && this.attributes.compensationTypeId !== +nameValue.value) {
                    o[nameValue.name] = +nameValue.value;
                    switch (enumerations.compensationTypeIds[nameValue.value].toLowerCase()) {
                    case 'salary':
                        // console.log('s...');
                        o.hourlyRate = null;
                        o.hoursPerWeek = null;
                        o.salary = true;
                        o.hourly = false;
                        break;
                    case 'hourly':
                        // console.log('h...');
                        o.basePay = null;
                        o.hourly = true;
                        o.salary = false;
                        break;
                    }
                    return;
                }
                if (nameValue.name.toLowerCase().indexOf('toggle') !== -1) {
                    // this should go first so jobOtherToggle (for example) doesn't confound
                    // the 'other' handler that follows
                    return;
                }
                if (nameValue.name.toLowerCase().indexOf('other') !== -1) {
                    // job id is special case where we don't set the id to an 'other' value
                    o[nameValue.name] = nameValue.value;
                    if (nameValue.name !== 'jobOther') {
                        o[nameValue.name.replace('Other', 'Id')] = enumerations.others[nameValue.name.replace('Other', 'Id')];
                    }
                    return;
                }
                if (nullIfZero[nameValue.name] && +nameValue.value === 0) {
                    o[nullIfZero[nameValue.name]] = null;
                }
                if (cullableNames[nameValue.name] || cullableValues[nameValue.value]) {
                    if (cullableValues[nameValue.value]) {
                        console.log(nameValue.name);
                    }
                    return;
                }
                if (!arrayTypes[nameValue.name]) {
                    o[nameValue.name] = numberify(nameValue.name, nameValue.value);
                } else {
                    if (!o[nameValue.name + 's']) {
                        o[nameValue.name + 's'] = [];
                    }
                    o[nameValue.name + 's'].push(numberify(nameValue.name, nameValue.value));
                }
            }.bind(this));
            // ideally we'd use save(o, {patch: true, ...}) for updates but it didn't append the id and
            // a quick look around didn't offer a remedy (or really much discussion on the topic so presumed PEBCAK)
            if (isCreate) {
                this.save(o, {
                    silent: true,
                    wait: true,
                    error: function (model, response) {
                        const error = (response.responseJSON && response.responseJSON.error) ? response.responseJSON.error : 'unknownError';
                        alert(error === 'existingUser' ? 'User already exists.' : 'Problem encountered.   (' + error + ')');
                        console.log('user.persist.create', {error: error});
                        router.navigate('/login/',  {trigger: true});
                    }
                });
            } else {
                this.set(o, {silent: true});
                if (this.hasChanged()) {
                    $.ajax({
                        contentType: 'application/json',
                        data: JSON.stringify(this.changed),
                        dataType: 'json',
                        headers: this.authorizationHeader(),
                        method: 'PATCH',
                        url: this.url + '/' + this.id,
                        success: (function () {
                            if (this.changed.dataSetId || this.changed.jobId) {
                                this.subscriptions.fetch({dataSetId: this.attributes.dataSetId, jobId: this.attributes.jobId, headers: this.authorizationHeader()});
                            }
                            _.each(this.changed, function (value, key) {
                                console.log('change:' + key);
                                this.trigger('change:' + key);
                            }.bind(this));
                        }.bind(this)),
                        error: function (model, response) {
                            const error = (response.responseJSON && response.responseJSON.error) ? response.responseJSON.error : 'unknownError';
                            alert('Problem encountered.   (' + error + ')');
                            console.log('user.persist.update', {error: error});
                        }
                    });
                } else {
                    console.log('user.persist.update - no changes to persist');
                }
            }
        },
        product: function () {
            return this.attributes.product;
        },
        recoverPassword: function (options) {
            $.ajax({
                dataType: 'json',
                url: configuration.apiUrlBase + '/users/password/' + options.email
            }).done (function (/*results, statusText, xhr*/) {
                analytics.event('password', 'recover');
                // console.log('user.recoverPassword', results);
            }).fail (function (xhr, errorText, error) {
                alert('Problem encountered (' + error.toString() + ')');
            });
        },
        resetPassword: function (options) {
            $.ajax({
                data: {password: options.password},
                dataType: 'json',
                method: 'POST',
                url: configuration.apiUrlBase + '/users/passwordRecovery/' + options.token
            }).done (function (/*results, statusText, xhr*/) {
                // console.log('user.resetPassword', results);
                analytics.event('password', 'reset');
                alert('Your password has been reset');
                router.navigate('/loginViaPassword/', {trigger: true});
            }).fail (function (xhr/*, errorText, error*/) {
                alert(xhr.responseJSON && xhr.responseJSON.error ? xhr.responseJSON.error : 'Could not reset your password');
            });
        },
        ready: function (callback) {
            if (this.initialized) {
                callback(null);
            } else {
                this.waitees.push(callback);
            }
        },
        update: function (serializedArray) {
            this.persist(serializedArray, false);
        },
        upgrade: function () {
            const jobId = this.attributes.jobId,
                dataSetId = this.attributes.dataSetId;
            // why does eslint get confused and think intervalId is unused?
            let intervalId, // eslint-disable-line no-unused-vars
                tries = 0;
            this.set({upgradeInProgress: true});
            intervalId = setInterval(function () {
                console.log('user.upgrade - trying (%s, %s)', tries, new Date().getTime());
                this.subscriptions.fetch({dataSetId: dataSetId, jobId: jobId, headers: this.authorizationHeader()}, function (error, data) {
                    if (data.subscriptionStatus === 'active') {
                        clearInterval(intervalId);
                        intervalId = null;
                        console.log('user.upgrade - it\'s here!');
                        this.set(_.extend({mode: 'concierge', upgradeInProgress: false}, this.subscriptions.toJSON()));
                    }
                }.bind(this));
                tries++;
                if (tries > 10) {
                    clearInterval(intervalId);
                    intervalId = null;
                    alert('Subscription not upgraded yet...');
                    console.log('user.upgrade - tries > 10');
                }
            }.bind(this), 2500);
        }
    });

module.exports = new User();
