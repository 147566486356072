'use strict';

const $ = require('jquery'),
    _ = require('lodash'),
    configuration = require('./configuration'),
    url = !configuration.mockdata.enumerations ? configuration.apiUrlBase + '/enumerations' : configuration.cdnUrlBase + '/mockdata/enumerations.json';

module.exports = {
    initialize: function (callback) {
        $.getJSON(url, function (enumerations) {
            const o = {},
                products = {};
            o.productIds = {};
            _.each(enumerations.products, function (product, id) {
                // products as {<id>: <product>} e.g., {4: 'private'}
                products[product.product] = id;
                o.productIds[id] = product.product;
            });
            o.productCombinationIds = {};
            _.each(enumerations.productCombinations, function (productCombination, id) {
                // product combinations as {<id>: <combination name>} e.g., {1: 'Private Equity'}
                productCombination.product = o.productIds[productCombination.productId];
                o.productCombinationIds[id] = productCombination.name;
            });
            // cull the 'other' items from their respective lists
            _.each(enumerations.others, function (id, key) {
                delete enumerations[key + 's'][id];
            });
            // we don't cull the jobs per product - instead we use the display orders to determine what's offered
            o.investmentJobIds = o.privateJobIds = o.ventureJobIds = enumerations.jobIds;
            o.investmentJobIdsDisplayOrder = enumerations.products[products.investment].jobIdsDisplayOrder;
            o.privateJobIdsDisplayOrder = enumerations.products[products.private].jobIdsDisplayOrder;
            o.ventureJobIdsDisplayOrder = enumerations.products[products.venture].jobIdsDisplayOrder;
            // if (enumerations.subscriptionPrices.dataSetsIds) {
            //     // fix typo dataSetsIds -> dataSetIds and jobsIds -> jobIds
            //     enumerations.subscriptionPrices.dataSetIds = enumerations.subscriptionPrices.dataSetsIds;
            //     _.each(enumerations.subscriptionPrices.dataSetIds, function (dataSet) {
            //         dataSet.jobIds = dataSet.jobsIds;
            //     });
            // } else {
            //     console.log('enumerations.initialize - subscriptionPrices format seems okay...');
            // }
            // faux ids for the yes/no questions
            o.signOnBonusIds = o.projectedBonusIds = {
                '0': 'No',
                '1': 'Yes'
            };
            o.basePayIsSignOnIds = {
                '0': 'Current Job',
                '1': 'Job Offer'
            };
            o.regionNames = {};
            _.each(enumerations.regionIds, function (region, id) {
                // regions as {<name>:<id>} e.g. {'Canada': 3} (can't use _.invert because we want the ids as integers...)
                o.regionNames[region] = +id;
            });
            // mash 'em all together and stash in the local enumerations object
            _.extend(this.enumerations, enumerations, o);
            // console.log(o);
            // console.log(this.enumerations.productCombinations);
            if (callback) {
                callback(null);
            }
        }.bind(this));
    },
    enumerations: {}
};
