'use strict';
const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'login col-12 col-md-10 offset-md-1'
    },
    events: {
        'click button': 'onClickButton',
        'click small':  'onClickButton'
    },
    tagName: 'div',
    template: templates.logIn,
    ui: {
        form: 'form'
    },
    //
    initialize: function () {
        this.listenTo(user, 'change:error', function () {
            console.log('login - user.change:error');
            // @acavan! okay to assume authentication is only time there can be a user error?
            alert('Problem encountered (' + user.get('error') + ')');
            console.log('login.user.change:error - %s', user.get('error'));
        });
    },
    onClickButton: function (event) {
        const objectify = function (serializedArray) {
            const o = {};
            _.each(serializedArray, function (nameValue) {
                o[nameValue.name] = nameValue.value;
            });
            return o;
        };
        let el, o;
        switch (event.currentTarget.dataset.action) {
        case 'logIn':
            o = objectify(this.ui.form.serializeArray());
            if (!o.product || o.product === 'credentials') {
                if (!this.ui.form[0].checkValidity()) {
                    this.ui.form.toggleClass('was-validated', true);
                    return;
                }
            } else {
                delete o.email;
                delete o.password;
                this.ui.form.toggleClass('was-validated', false);
            }
            user.authenticate(o);
            break;
        case 'recoverPassword':
            o = objectify(this.ui.form.serializeArray());
            this.$el.find('[name=password]').attr('disabled', true); // checkValidity will grouse if empty password...
            if (this.ui.form[0].checkValidity()) {
                alert('An email with instructions for how to reset your password will be sent to ' + o.email);
                user.recoverPassword({email: o.email});
            } else {
                alert('A properly formatted email address is required to recover your password');
            }
            this.$el.find('[name=password]').attr('disabled', false);
            break;
        case 'showPassword':
            el = this.$el.find('input[name=password]')[0];
            el.type = el.type === 'password' ? 'text' : 'password';
            break;
        default:
            break;
        }
    }
});
