'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    _ = require('lodash'),
    //
    user = require('../models/user'),
    router = require('../utilities/router'),
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'password col-12 col-md-10 offset-md-1'
    },
    events: {
        'click button': 'onClickButton',
        'click small':  'onClickButton'
    },
    tagName: 'div',
    template: templates.password,
    token: null,
    ui: {
        form: 'form'
    },
    //
    initialize: function () {
        const match = (window.location.search + '&').match(/token=(.*)&/);
        if (match && match.length > 0) {
            this.token = match[1];
        }
    },
    onClickButton: function (event) {
        const o = {},
            action = event.currentTarget.dataset.action;
        let el, serializedArray;
        switch (action) {
        case 'resetPassword':
            serializedArray = this.ui.form.serializeArray();
            _.each(serializedArray, function (nameValue) {
                o[nameValue.name] = nameValue.value;
            });
            if (!this.ui.form[0].checkValidity()) {
                this.ui.form.toggleClass('was-validated', true);
                return;
            }
            user.resetPassword({password: o.password, token: this.token});
            break;
        case 'showPassword':
            el = this.$el.find('input[name=password]')[0];
            el.type = el.type === 'password' ? 'text' : 'password';
            break;
        default:
            console.log('password.onClickButton - event:%s', action);
            break;
        }
    },
    onRender: function () {
        if (!this.token) {
            alert('password.initialize - no token found');
            _.defer(function () {
                router.navigate('#login', {trigger: true});
            });
        }
    }
});
