'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    // _ = require('lodash'),
    //
    Chart = require('./chart'),
    Table = require('./table');

module.exports = Marionette.View.extend({
    attributes: function () {
        return {class: 'report' + (this.options.class ? ' ' + this.options.class : '')};
    },
    regions: {
        content: {el: '[data-region=content]', replaceElement: true}
    },
    tagName: 'div',
    template: function () {return '<div data-region="content"></div>';},
    onRender: function () {
        const types = {
            chart: Chart,
            table: Table
        };
        this.showChildView('content', new types[this.model.get('type')]({model: this.model, mobile: this.options.mobile, upgradable: this.options.upgradable}));
    },
    pending: function () {
        this.getChildView('content').pending();
    }
});
