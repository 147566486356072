'use strict';

const Backbone = require('backbone'),
    // $ = require('jquery'),
    _ = require('lodash'),
    //
    configuration = require('../../common/configuration'),
    enumerations = require('../../common/enumerations').enumerations,
    user = require('../models/user');

module.exports = Backbone.Model.extend({
    columnHeaders: function () {
        if (this.attributes.columnHeaders.length === 0) {
            return [];
        }
        return _.concat([this.attributes.columnRowHeader ? this.attributes.columnRowHeader : '&nbsp;'], this.attributes.columnHeaders);
    },
    parse: function (report/*, options*/) {
        const title = function (report) { // eslint-disable-line no-shadow
                let title = report.id, // eslint-disable-line no-shadow
                    regionId;
                const job = enumerations.jobIds[user.get('jobId')];
                switch (report.id) {
                case 'cvcJob':
                case 'ifJob':
                case 'pcJob':
                    title = job;
                    break;
                case 'cvcJobEquiv':
                    title = 'Job Equivalent for Investment Firm';
                    break;
                case 'ifJobFirmType':
                    title = job + ' + ' + enumerations.investmentFirmTypeIds[user.get('investmentFirmTypeId')];
                    break;
                case 'ifJobAUM':
                    title = job + ' + AUM';
                    break;
                case 'pcJobIndustry':
                    title = job + ' + ' + enumerations.industryIds[user.get('industryId')];
                    break;
                case 'pcJobRegion':
                    regionId = user.get('regionId');
                    title = job + ' + ' + (enumerations.regionNames['United States'] === regionId ? user.get('postalCode') : enumerations.regionNames[regionId]) ;
                    break;
                default:
                    break;
                }
                return title;
            },
            o = {
                id: report.id,
                type: 'table',
                title: title(report),
                columnHeaders: [
                    'Average',
                    'Minimum',
                    '25th Percentile',
                    'Median',
                    '75th Percentile',
                    'Maximum'
                ],
                columnUnits: [],
                columnsRowHeader: '',
                rowHeaders: [],
                rowValues: [],
                rowTypes: []
            },
            rowHeaders = {
                basePay: 'Base pay',
                basePaybonusTotal: 'Total pay',
                bonus: 'Bonus',
                percentCarriedInterest: 'Percent carried interest',
                percentOfOptionsExcludingFounderShares: 'Options excluding founder shares',
                projectedBonus: 'Projected bonus'
            },
            percentTypes = {
                percentCarriedInterest: true,
                percentOfOptionsExcludingFounderShares: true
            };
        _.each(_.keys(report.rows), function (key) {
            o.rowHeaders.push(rowHeaders[key] || key);
            o.rowTypes.push(percentTypes[key] ? 'percent' : 'currency');
        });
        _.each(report.rows, function (row, name) {
            const a = [];
            let needUnits = true;
            _.each(['average', 'min', 'firstQ', 'median', 'thirdQ', 'max'], function (stat) {
                if (row[stat] !== 'omitted') {
                    if (percentTypes[name]) {
                        a.push(parseFloat(row[stat]).toFixed(2));
                    } else {
                        a.push(Math.floor(row[stat]));
                    }
                } else {
                    a.push(row[stat]);
                }
                if (needUnits) {
                    // console.log(name);
                    if (percentTypes[name]) {
                        o.columnUnits.push('percent');
                    } else {
                        o.columnUnits.push('currency');
                    }
                }
                needUnits = false;
            });
            o.rowValues.push(a);
        });
        // console.log(o);
        return o;
    },
    rows: function () {
        const columnUnits = this.attributes.columnUnits,
            rowValues = this.attributes.rowValues,
            rowHeaders = this.attributes.rowHeaders,
            rows = [],
            format = function (value, unit) {
                const thousands = function (v) {
                    const a = v.toString().split('.');
                    a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    return a.join('.');
                };
                if (value !== 'omitted') {
                    switch (unit) {
                    case 'currency':
                        value = '$' + thousands(value);
                        break;
                    case 'percent':
                        value = value + '%';
                        break;
                    default:
                        break;
                    }
                } else {
                    value = configuration.blurString;
                }
                return value;
            };
        _.each(rowValues, function (values, index) {
            rows.push({
                header: rowHeaders[index] || '',
                values: _.map(values, function (value) {return format(value, columnUnits[index]);})
            });
        });
        return rows;
    }
});
