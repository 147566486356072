'use strict';

const Backbone = require('backbone'),
    _ = require('lodash'),
    //
    Report = require('../models/report'),
    user = require('../models/user'),
    configuration = require('../../common/configuration');

module.exports = Backbone.Collection.extend({
    model: Report,
    initialize: function (models, options) {
        this.via = options.via;
    },
    parse: function (results/*, options*/) {
        const a = [];
        // console.log('reports.parse - via:%s', this.via);
        this.reportIdsDisplayOrder = results.reportIdsDisplayOrder;
        _.each(results.reports, function (report) {
            a.push(report);
        });
        // console.log(a);
        return a;
    },
    refresh: function () {
        let url;
        if (!user.authenticated()) {
            return;
        }
        if (!configuration.mockdata.reports) {
            url = configuration.apiUrlBase + '/compensation';
        } else {
            url = configuration.cdnUrlBase + '/mockdata/compensation_' + user.product() + '_' + (user.hasConcierge() ? 'paid' : 'gratis') + '.json';
        }
        this.fetch({
            headers: user.authorizationHeader(),
            method: 'GET',
            reset: true,
            url: url
        });
    },
    userReport: function () {
        const reportData = {
            id: 3,
            type: 'table',
            title: 'My Compensation',
            columnHeaders: [],
            columnRowHeader: '',
        };
        if (user.get('salary')) {
            reportData.rowHeaders = [
                'Base Salary',
                'Bonus'
            ];
            reportData.rowValues = [
                [user.get('basePay') || 0],
                [user.get('bonus') || 0]
            ];
            reportData.columnUnits = [
                'currency',
                'currency'
            ];
        } else {
            reportData.rowHeaders = [
                'Hourly Rate',
                'Hours Per Week',
                'Bonus'
            ];
            reportData.rowValues = [
                [user.get('hourlyRate') || 0],
                [user.get('hoursPerWeek') || 0],
                [user.get('bonus') || 0]
            ];
            reportData.columnUnits = [
                'currency',
                'numeric',
                'currency'
            ];
        }
        return new this.model(reportData);
    }
});
