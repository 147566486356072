'use strict';

const  analytics = require('universal-ga'),
    Marionette = require('backbone.marionette'),
    //
    templates = require('../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'terms-of-service col-12'
    },
    tagName: 'div',
    template: templates.termsOfUse,
    //
    onRender: function () {
        analytics.pageview('termsOfUse');
    }
});

